import React, { useContext, useEffect, useState } from 'react'
import StaticHeader from '../../../staticpagis/StaticHeader'
import StaticFooter from '../../../staticpagis/StaticFooter'
import { PlanList } from '../../../Static_Services/StaticServices'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import HeaderChousePlan from '../../../public/containers/header/HeaderChousePlan'
import { MyContext } from '../../../App'

export const Chooseplan = () => {

    const location = useLocation();
    const trailDays = process.env.REACT_APP_trailDays;
    const planDays = process.env.REACT_APP_planDays;
    const navigate = useNavigate();
    const [backURL,setBackURL] = useState();

  const {updatePlandata} = useContext(MyContext);
 

    const plan_id = location.state ? location.state.planId : "";

    const [selectedPlanId, setSelectedPlanId] = useState(plan_id);


    const [plist, setPlist] = useState([]);
    const [record, setRecord] = useState();

    const get_plan_list = async () => {
        const resp = await PlanList();
        if (resp) {
            setRecord(resp.data.message);
            setPlist(resp.data.data);
            updatePlandata(resp.data.data);
        }
    }
    useEffect(() => {
        get_plan_list();
    }, [])

    useEffect(()=>{
        if(location && location.state && location.state.backURL){
            setBackURL(location.state.backURL);
        }
    },[location])


      const pricingCards = document.querySelectorAll('.pricing-card');

  // Add event listener to each pricing card
  pricingCards.forEach((card) => {
    card.addEventListener('click', handlePricingCardClick);
  });

  // Event handler for pricing card click
  function handlePricingCardClick(event) {
    // Remove active class from all pricing cards
    pricingCards.forEach((card) => {
      card.classList.remove('active');
    });

    // Add active class to the clicked pricing card
    event.currentTarget.classList.add('active');
  }

  const navigateHandler = () =>{
    if(backURL){
        navigate(backURL,{state:{plan_id : selectedPlanId}});
    }else{
        navigate('/studio-registration',{state:{plan_id : selectedPlanId}});
    }
   
  }


 const onselectPlan = (plan) => {
    setSelectedPlanId(plan.plan_id);
        localStorage.removeItem('st_selected-plan-info');
        localStorage.setItem('st_selected-plan-info', JSON.stringify(plan));
  
    // let selectedplan = this.selectedPlan;
    // if(selectedplan.valid){
    //   console.log(this.planObj);
    //   if(this.returnUrl){
    //     localStorage.removeItem('st_selected-plan-info');
    //     localStorage.setItem('st_selected-plan-info', <any>JSON.stringify(this.planObj));
    //     var url;
    //     if(parseInt(this.planObj.amount) > 0){
    //       url = '/paycard-setup';
    //     } else {
    //       url = '/business-profile';
    //     }
    //     this.route.navigateByUrl(url);   
    //   }
    //   else{
    //     localStorage.setItem('st_selected-plan-info', <any>JSON.stringify(this.planObj));
    //     this.route.navigateByUrl('/signup', { state: { selectedPlandata:  this.planObj}});
    //   }
     
    // }else{
    //   console.log('Something went wrong');
    //   return false;
    // }
  }


  return (
        <>
        {/* {console.log("Selected Plan Id",selectedPlanId)} */}
            <HeaderChousePlan />
            <div className="middle-content-wrp" style={{ paddingTop: "120px" }}>
                {/* image layer */}
                <div className="signwrapp-back-layer">
                    <img src="assets/images/pictures/watermark_bg.png" />
                </div>
                {/* img layer */}
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-12 relative">
                            <div className="stepsinfo-fill">
                                <ul className="steps">
                                    <li className="completed-step" />
                                    <li />
                                    <li />
                                </ul>
                            </div>
                            <div className="middle-innerbox p-4 choose-plan-sec ml-sm-5">
                                <span className="brand-light text-uppercase d-block">
                                    Step 1 of 3
                                </span>
                                <h3 className="title mt-2">Choose the plan that's right for you.</h3>
                                <span className="brand-light d-block">
                                    You can upgrade the plan anytime after signing up.
                                </span>
                                {/*                         
                                <form autoComplete="off" onSubmit={formSubmit} style={{ minHeight: 500 }}> */}
                                <div className="pricing-studio-cards py-2 my-2">

                                    <div className="pricingCard pt-4r">
                                        {plist.length > 0 ? "" :
                                            <div className="text-center">
                                                <img src="assets/images/planloader.gif" className="img-fluid" />
                                            </div>
                                        }

                                        {record === "Records Found" ?
                                                plist.map((plan) => (
                                                    <div className="cardsDetails mb-2" key={plan.plan_id} onClick={()=>onselectPlan(plan)}>
                                                        <div className={plan.plan_id == selectedPlanId ? "pricing-card text-center px-4 pt-4 pb-4 border-radius6 white-bg active" :"pricing-card text-center px-4 pt-4 pb-4 border-radius6 white-bg" }>
                                                            <img src="assets/images/logo_green_small.png" alt="Logo" />
                                                            <div className="customradio-card py-2">
                                                                <p className="black-color font-w-600 font-20 m-0 card-title">{plan.plan_name}</p>
                                                            </div>
                                                            <h5 className="black-color mb-3 font-w-400">$ {plan.amount} / Month*</h5>
                                                        </div>
                                                    </div>
                                                ))
                                            :
                                            <div style={{ minHeight: 400 }}>
                                                <h4 className="title text-center" style={{ marginTop: 100 }}>
                                                    No plan available at that movement please try again later
                                                </h4>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <p className="brand-light mt-4">* Plus Applicable Taxes</p>
                                <h4>Website Hosting</h4>
                                <p className="brand-light mt-2"> Warrior offers an integrated website solution that allows your data to be automatically shown on your website. Contact help@warriorsms.com for more information about the costs and requirements. </p>
                                <p className="brand-light mt-4">
                                    Pick your package based on the number of Business Users you will
                                    need. A business user includes the Owner, Managers, Staff and
                                    Instructors. You can always increase your package once you open
                                    your account and start adding users. You won’t be billed until
                                    after the {planDays} day free trial. You can cancel any time within the {planDays} day free trial free of charge.
                                </p>
                                {selectedPlanId ?
                                    // <Link to="/studio-registration" state={selectedPlanId}>
                                        <button onClick={navigateHandler} className="btn btn-brandbluegrad mt-4" type="button">
                                        Continue
                                    </button>
                                    // </Link>
                                    :
                                    <button className="btn btn-brandbluegrad mt-4" disabled type="button">
                                        Continue
                                    </button>
                                }
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <StaticFooter />
        </>
    )
}
