import React, { useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Footer } from "../../../../../public/containers/footer/Footer";
import { passes_list } from "../../../../../Studio-Services/Studio_Services";
import { PassList } from "./PassList";
import { AddPassForm } from "./AddPassForm";

export const Addnewpass = () => {
  const [timepass, setTimepass] = useState([]);
  const [recurrigpass, setRecurrigpass] = useState([]);
  const [punchpass, setPunchpass] = useState();
  const [loder, setLoder] = useState(true);
  const [ recurringPunchPass, setRecurringPunchPass] = useState([]);

  const passListHandler = async () => {
    setLoder(true);
    const passPre = { "page_no": "1" }
    const resp = await passes_list(passPre);
    if (resp) {
      setLoder(false);
      // setPasslist(resp.data.data);
      const passlist = resp.data.data;
      const timep = [];
      const recuring = [];
      const punch = [];
      const RecurringPunch = [];

      if (passlist) {
        const logo = localStorage.getItem("logo");
        passlist.forEach((value) => {
          if (value.pass_type === "Time Frame") {
            timep.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: `Valid For ${value.pass_days} Days`,
              cardTitle: value.pass_name,
              price: `$ ${value.amount}`,
              tax: "Plus Tax",
              value: value
            })
          } else if (value.pass_type === "Recurring Membership") {
            recuring.push({
              memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
              validText: `Valid For ${value.pass_days} Days`,
              cardTitle: value.pass_name,
              price: `$ ${value.amount}`,
              tax: "Plus Tax",
              value: value
            })
          } else if (value.pass_type === "Punch Card") {
            if (value.is_recurring_billing === 'yes') {
              RecurringPunch.push({
                memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
                validText: `Valid For ${value.pass_days} Classes`,
                cardTitle: value.pass_name,
                price: `$ ${value.amount}`,
                tax: "Plus Tax",
                value: value
              })
            } else {
              punch.push({
                memberShipImg: logo ? logo : "https://staging.warriorsms.com/superadmin/uploads/business/1664736345WarriorMan_icon2_CMYK.png",
                validText: `Valid For ${value.pass_days} Classes`,
                cardTitle: value.pass_name,
                price: `$ ${value.amount}`,
                tax: "Plus Tax",
                value: value
              })
            }
          }
        });
        setTimepass(timep);
        setRecurrigpass(recuring);
        setPunchpass(punch);
        setRecurringPunchPass(RecurringPunch);
      }
    }
    setLoder(false);
  }

  useEffect(() => {
    passListHandler();
  }, []);


  return (
    <>
      {loder && <div className='MainLoader'><Spinner animation="border" role="status" className='Loader'></Spinner></div>}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col className="p-0 RightCol">
              <HeaderDashboard />
              <div className="RightSide">
                <section className="addClient innerContent">

                  <Container className="pt-5">
                    <h4>List of Your Passes</h4>
                    <p className="m-2 mb-4">
                      Every class or workshop requires a pass to reserve a space.
                      Set up your list of passes and as you put a new class or
                      workshop on the calendar you can pick which passes will be
                      available for the customer to use for signing up.
                    </p>
                    <div className='AddClientForm AddClientContent'>
                      <div className="PassList">
                        <h5>Pass List</h5>
                        <div className="CardCarousel mt-5">
                          <h5>Recurring Membership</h5>
                          <PassList pass={recurrigpass} />
                        </div>
                        <div className="TimeCard mt-5">
                          <h5>Time Frame</h5>
                          <PassList pass={timepass} />
                        </div>
                        <div className="PunchCard mt-5">
                          <h5>Punch Card</h5>
                          <PassList pass={punchpass} />
                        </div>
                        <div className="PunchCard mt-5">
                          <h5>Recurring Punch Card</h5>
                          <PassList pass={recurringPunchPass} />
                        </div>
                      </div>
                      <AddPassForm passList={passListHandler} />
                    </div>
                  </Container>
                </section>
                <Footer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
