import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../comman_page/Sidebar";
import { HeaderDashboard } from "../../comman_page/HeaderDashboard";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Footer } from "antd/es/layout/layout";
import { SharedButton } from "../../../../../sharedComponents/Button";
import { BsCartPlusFill } from "react-icons/bs";
import { ProductCardList } from "../../../../../sharedComponents/ProductCardList";
import { PurchaseMembershipCard } from "../../../../../sharedComponents/PurchaseMembershipCard";
import { add_cart, add_free_pass, cart_list, search_customer_details } from "../../../../../Studio-Services/Studio_Services";
import { MyContext } from "../../../../../App";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../../../../Images/logo_white_web.png"
import { MemberProductCart } from "../../../../../sharedComponents/MemberProductCart";


export const PurchaseProduct = () => {
  const navigate = useNavigate();

  const [timePass, setTimePass] = useState([]);
  const [punchPass, setPunchPass] = useState([]);
  const [recurringPass, setRecurringPass] = useState([]);
  const [passValueRecurring, setPassValueRecurring] = useState([]);
  const [passValueTimeFram, setPassValueTimeFram] = useState([]);
  const [passValuePunch, setassValuePunch] = useState([]);


  const [loder, setLoder] = useState(false);


  const [purchasetimePass, setPurchaseTimePass] = useState([]);
  const [purchasepunchPass, setPurchasePunchPass] = useState([]);
  const [purchaserecurringPass, setPurchaseRecurringPass] = useState([]);

  const [purchasepassValueRecurring, setPurchasePassValueRecurring] = useState([]);
  const [purchasepassValueTimeFram, setPurchasePassValueTimeFram] = useState([]);
  const [purchasepassValuePunch, setPurchaseassValuePunch] = useState([]);

  const [nodata, setNodata] = useState(0);


  const location = useLocation();
  const context = useContext(MyContext);
  const [userData, setUserData] = useState([]);
  const [clientID, setClientID] = useState();
  const [costmoreData, setCostmoreData] = useState();
  const [customerDetails, setCustomerDetails] = useState([]);
  const [cartLists, setCartLists] = useState();
  const [familyMember, setFamilyMember] = useState([]);

  const [finalClientId, setFinalClientId] = useState();
  const [family_member_id, setFamily_member_id] = useState();
  const [isPassPurchaseFlow, setIsPassPurchaseFlow] = useState(false);
  const [preClassID, setPreClassID] = useState();
  const [preSchedule_id, setPreSchedule_id] = useState();
  const [recurringPunch, setRecurringPunch] = useState([]);


  // const cltID = sessionStorage.getItem("clientid");


  useEffect(() => {
    if (location && location.state && location.state.data && location.state.data.id) {
      setClientID(location.state.data.id);
      clientSelect(location.state.data, '', 2);
      setPreClassID();
      setPreSchedule_id();
      setIsPassPurchaseFlow(false);
      localStorage.removeItem('st_isPassPurchaseFlow');
      if (!location.state.family_member_id) {
        localStorage.removeItem('memberUserId');
      }
      localStorage.removeItem('allsetData');
    } else if (location && location.state && location.state.data && location.state.data.data && location.state.data.data.id) {
      setClientID(location.state.data.data.id);
      // familyHandler(location.state.family_member_id) //////////////////////////////////////////////////////////////////Meemmmbar Function
      setFamily_member_id(location.state.family_member_id);
      if (location.state.family_member_id) {
        localStorage.setItem('memberUserId', location.state.family_member_id);
      } else { localStorage.removeItem('memberUserId'); }
      clientSelect(location.state.data.data, location.state.class_id);
      setPreClassID(location.state.class_id);
      setPreSchedule_id(location.state.schedule_id);
      if (JSON.parse(localStorage.getItem('st_isPassPurchaseFlow'))) {
        setIsPassPurchaseFlow(true);
      }
    }
  }, [location])

  // useEffect(() => {
  //   localStorage.removeItem('memberUserId');
  // }, [])

  const clientSelect = async (data, class_id, checkFamily = 1) => {
    let request = {
      "client_id": data.id,
      "class_id": class_id
    }
    const response = await search_customer_details(request);
    if (response) {
      setCostmoreData(response.data.data);
      localStorage.setItem('st_customerDetails', JSON.stringify(response.data.data));
      setFinalClientId(response.data.data.id);
      // setCurrentPassesRecord();
    } else {
      setCostmoreData('');
      localStorage.removeItem('st_customerDetails');
      setFinalClientId('');
    }
  }

  const back = () => {
    if (isPassPurchaseFlow && preClassID != '') {
      navigate('/client-search', { state: { back: 'pre', class_id: preClassID, schedule_id: preSchedule_id, serchKey: JSON.parse(localStorage.getItem('st_searchedClients')) } });
    } else {
      navigate('/make-a-purchase');
    }
  }

  const cartList = async () => {
    const fData = {
      "pageid": "1",
      "client_id": family_member_id ? family_member_id : costmoreData.id
    }
    const resp = await cart_list(fData);
    if (resp) {
      setCartLists(resp.data.data);
    } else setCartLists([]);
    const Cosdata = {
      "customer": costmoreData.id
    }

    const resp1 = await search_customer_details(Cosdata);
    if (resp1) {
      setCustomerDetails(resp1.data.data);
    }
  }

  const familyHandler = async (e) => {
    if (e.target.value.length === 0) {
      localStorage.removeItem('memberUserId');
    } else {
      localStorage.setItem('memberUserId', e.target.value);
    }
    let customerId = (e.target.value.length > 0) ? e.target.value : costmoreData.id;
    setFamily_member_id(customerId);
    const fData = {
      "pageid": "1",
      "client_id": customerId
    }
    const resp = await cart_list(fData);
    if (resp) {
      setCartLists(resp.data.data);
    } else setCartLists([]);
  }

  const setOptions = () => {
    const mbr = [];
    if (costmoreData.family_member) {
      (costmoreData.family_member).map((e) => (
        mbr.push({
          value: e.id, label: `Name :- ${e.member_name} , Relation: ${e.relation}`
        })
      ))
      setFamilyMember(mbr);
    } else setFamilyMember([]);
  }

  const passList = async () => {
    const timefram = [];
    const punchfram = [];
    const recuring = [];
    const RcPunch = [];
    if (costmoreData && costmoreData.avaliable_passes_data) {
      const fData = costmoreData.avaliable_passes_data;
      fData.map((e) => (
        e.pass_type === "Time Frame" ?
          timefram.push({
            pass_id: e.id,
            service_type: "1",
            quantity: "1",
            memberShipImg: e.studio_logo ? e.studio_logo : "",
            cardTitle: e.pass_name,
            price: e.amount,
            validText: `Valid For ${e.pass_days} Days`,
            taxTaxt: "Plus Tax",
            isAutoAdd: e?.isAutoAdd,
          })
          : e.pass_type === "Recurring Membership" ?
            recuring.push({
              pass_id: e.id,
              memberShipImg: e.studio_logo ? e.studio_logo : "",
              cardTitle: e.pass_name,
              price: e.amount,
              service_type: "1",
              quantity: "1",
              validText: `Valid For ${e.pass_days} Days`,
              taxTaxt: "Plus Tax",
              isAutoAdd: e?.isAutoAdd,
            })
            : e.pass_type === "Punch Card" ?
              e?.is_recurring_billing == 'yes' ?
                RcPunch.push({
                  pass_id: e.id,
                  memberShipImg: e.studio_logo ? e.studio_logo : "",
                  cardTitle: e.pass_name ? e.pass_name : "",
                  price: e.amount ? e.amount : "",
                  service_type: "1",
                  quantity: "1",
                  validText: `Valid For ${e.pass_days} Classes`,
                  taxTaxt: "Plus Tax",
                  isAutoAdd: e?.isAutoAdd,
                })
                :
                punchfram.push({
                  pass_id: e.id,
                  memberShipImg: e.studio_logo ? e.studio_logo : "",
                  cardTitle: e.pass_name ? e.pass_name : "",
                  price: e.amount ? e.amount : "",
                  service_type: "1",
                  quantity: "1",
                  validText: `Valid For ${e.pass_days} Classes`,
                  taxTaxt: "Plus Tax",
                  isAutoAdd: e?.isAutoAdd,
                })
              : ""
      ))
      setTimePass(timefram);
      setPunchPass(punchfram);
      setRecurringPass(recuring);
      setRecurringPunch(RcPunch);
    }

    const Purchase_timefram = [];
    const Purchase_recuring = [];
    const Purchase_punchfram = [];

    if (costmoreData && costmoreData.purchase_passes_data) {
      const purchaseData = costmoreData.purchase_passes_data;

      purchaseData.map((e) => (
        e.pass_type_name === "Time Frame" ?
          Purchase_timefram.push({
            pass_id: e.id,
            service_type: "1",
            quantity: "1",
            memberShipImg: e.business_logo ? e.business_logo : "",
            cardTitle: e.pass_name,
            price: e.amount,
            startDate: `Start Date  ${e.start_date}`,
            taxTaxt: "Plus Tax"
          })
          : e.pass_type_name === "Recurring Membership" ?
            Purchase_recuring.push({
              pass_id: e.id,
              memberShipImg: e.business_logo ? e.business_logo : "",
              cardTitle: e.pass_name,
              price: e.amount,
              service_type: "1",
              quantity: "1",
              startDate: `Start Date  ${e.start_date}`,
              taxTaxt: "Plus Tax"
            })
            : e.pass_type_name === "Punch Card" ?
              Purchase_punchfram.push({
                pass_id: e.id,
                memberShipImg: e.business_logo ? e.business_logo : "",
                cardTitle: e.pass_name ? e.pass_name : "",
                price: e.amount ? e.amount : "",
                service_type: "1",
                quantity: "1",
                startDate: `Start Date  ${e.start_date}`,
                taxTaxt: "Plus Tax"
              })
              : ""
      ))

      setPurchaseTimePass(Purchase_timefram);
      setPurchaseRecurringPass(Purchase_recuring);
      setPurchasePunchPass(Purchase_punchfram);
    }
  }

  useEffect(() => {
    if (costmoreData) {
      setOptions();
      cartList();
      passList();
    }
  }, [costmoreData])

  const recuringHandler = (data) => {
    setPassValueRecurring(data);
  }

  const timeFramHandler = (data) => {
    setPassValueTimeFram(data);
  }

  const punchHandler = (data) => {
    setassValuePunch(data)
  }

  // Puchasss

  const purchaserecuringHandler = (data) => {
    setPurchasePassValueRecurring(data);
  }

  const purchasetimeFramHandler = (data) => {
    setPurchasePassValueTimeFram(data);
  }

  const purchasepunchHandler = (data) => {
    setPurchaseassValuePunch(data)
  }

  useEffect(() => {
    if (context.context !== undefined && context.context !== null) {
      const udata = context.context.uData;
      setUserData({
        business_img: udata.business_img,
        logo: udata.logo
      });
    }
  }, [context])




  const addCart = async (service_type, data, quantity) => {
    if (data?.isAutoAdd == "Yes") {
      try {
        setLoder(true);
        const payload = {
          'service_type': data?.service_type,
          'service_id': data?.pass_id,
          'amount': data?.price,
          'quantity': 1,
          'client_id': family_member_id ? family_member_id : costmoreData.id,
        }
        const resp = await add_free_pass(payload);
        if (resp) {
          Swal.fire({
            html: `
          <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
            SUCCESS
          </h4>
          <p class="text-center my-4 textcolor">
            <small class="textWhite">
              ${resp.data.message}
            </small>
          </p>
          `,
            showClass: {
              popup: 'custom-dialog-container',
            },
            customClass: {
              confirmButton: 'custom-button custom-button-size modalbtn',
            },
          })
        }
      } catch (error) {
        console.error("@error------", error);
      } finally {
        setLoder(false);
      }
    } else {
      let fData = '';
      if (service_type === "3") {
        fData = {
          service_type: service_type,
          service_id: data.id,
          amount: data.amount,
          quantity: quantity,
          client_id: family_member_id ? family_member_id : costmoreData.id
        }
      } else {
        fData = {
          service_type: service_type,
          service_id: data.pass_id,
          amount: data.price,
          quantity: quantity,
          client_id: family_member_id ? family_member_id : costmoreData.id
        }
      }

      //    Add To Cart
      const resp = await add_cart(fData);
      if (resp) {
        cartList();
        Swal.fire({
          html: `
        <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
        <h4 class="title text-uppercase text-center mt-4 textWhite">
          SUCCESS
        </h4>
        <p class="text-center my-4 textcolor">
          <small class="textWhite">
            ${resp.data.message}
          </small>
        </p>
        `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
        })
      }
    }
  }

  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <div className="MainDashboard">
        <div className="MainLayout">
          <Row>
            <Sidebar />
            <Col>
              <div className="RightSide">
                <HeaderDashboard />

                <section className="addClient innerContent">
                  <SharedButton title={"Back"} onClick={() => back()} className={"BackButton"} />
                  <div className="AddClientContent">
                    <Container>
                      {nodata === 0 ?
                        <>
                          {
                            costmoreData
                              ?
                              <div className="PurchaseProfileProduct">
                                < img
                                  src={costmoreData.profile_img ? costmoreData.profile_img : "https://staging.warriorsms.com/superadmin/uploads/user/p.png"}
                                  alt=""
                                  className="purchaseDP img-fluid"
                                />
                                <div className="customerDetails">
                                  <h3>{costmoreData.name}</h3>
                                  <p>Customer</p>
                                  <p>{costmoreData.date_of_birth}</p>
                                  <p>{costmoreData.email}</p>
                                </div>
                              </div>
                              : ""}

                          <Row>
                            <Col md={6}>
                              <div className="form-group relative">
                                <p className="text-left font-14 mb-2 mt-3" style={{ fontWeight: '600', color: 'black' }}> FAMILY MEMBERS </p>
                                <select className="form-control white-color" name="family_member_id"
                                  onChange={familyHandler}>
                                  <option value=''>Select</option>
                                  {familyMember.length > 0 && familyMember.map((data) => (
                                    <>
                                      {/* <img src={data.image} alt=''/> */}
                                      <option value={data.value} selected={data.value === family_member_id}>{data.label}</option>
                                    </>
                                  ))}
                                </select>
                              </div>
                              {/* <SharedSelect
                              name="instructor"
                              lable={"FAMILY MEMBERS"}
                              options={familyMember}
                              value={family_member_id}
                              onChange={familyHandler}
                            /> */}
                            </Col>
                          </Row>

                          <Row className="my-5">
                            <Col>
                              <h4>CURRENT AVAILABLE PRODUCTS</h4>

                            </Col>
                            <Col>
                              <SharedButton
                                title={cartLists && cartLists.total_item ? cartLists.total_item : ""}
                                iconNode={<BsCartPlusFill />}
                                onClick={() => { navigate('/cart', { state: { id: costmoreData.id, family_member_id: family_member_id } }); }}
                                variant={"primary"}
                                className={"AddCartItem"}
                              />
                            </Col>
                          </Row>
                          <div className="product-Item">
                            <Row>
                              <Col>
                                <ProductCardList onClick={addCart} data={costmoreData && costmoreData.avaliable_product_data ? costmoreData.avaliable_product_data : ""} />
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col>
                              <div className="ProductMemberCart">
                                <h3 className="mt-4">CURRENT AVAILABLE PASSES</h3>
                                <div className="CardCarousel mt-5">
                                  <h5 className="grropCard">Recurring Membership</h5>
                                  <Row>
                                    <MemberProductCart pass={recurringPass} setValue={recuringHandler} onClick={addCart} />
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <div className="ProductMemberCart">
                                <div className="CardCarousel mt-5">
                                  <h5 className="grropCard">Time Frame</h5>
                                  <Row>
                                    <MemberProductCart pass={timePass} setValue={timeFramHandler} onClick={addCart} />
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="ProductMemberCart">
                                <div className="CardCarousel mt-5">
                                  <h5 className="grropCard">Punch Card</h5>
                                  <Row>
                                    <MemberProductCart pass={punchPass} setValue={punchHandler} onClick={addCart} />
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <div className="ProductMemberCart">
                                <div className="CardCarousel mt-5">
                                  <h5 className="grropCard">Recurring Punch Card</h5>
                                  <Row>
                                    <MemberProductCart pass={recurringPunch} setValue={punchHandler} onClick={addCart} />
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          {/* Puechasese Passes */}

                          <div className="purchaseMemberCard">
                            <Row>
                              <Col>
                                <div className="ProductMemberCart">
                                  <h3 className="mt-4">PURCHASED PASSES</h3>
                                  <div className="CardCarousel mt-5">
                                    <h5 className="grropCard">Recurring Membership</h5>
                                    <Row>
                                      <PurchaseMembershipCard pass={purchaserecurringPass} setValue={purchaserecuringHandler} />
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <div className="ProductMemberCart">
                                  <div className="CardCarousel mt-5">
                                    <h5 className="grropCard">Time Frame</h5>
                                    <Row>
                                      <PurchaseMembershipCard pass={purchasetimePass} setValue={purchasetimeFramHandler} />
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="ProductMemberCart">
                                  <div className="CardCarousel mt-5">
                                    <h5 className="grropCard">Punch Card</h5>
                                    <Row>
                                      <PurchaseMembershipCard pass={purchasepunchPass} setValue={purchasepunchHandler} />
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </>
                        :
                        <Row style={{ textAlign: "center" }}>
                          <Col>
                            <h1>No Record </h1>
                          </Col>
                        </Row>
                      }
                    </Container>
                  </div>
                </section>
                <Footer />
              </div>
            </Col>
          </Row >
        </div >
      </div >
    </>
  );
};
