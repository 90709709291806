import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import StaticHeader from '../../../staticpagis/StaticHeader'
import StaticFooter from '../../../staticpagis/StaticFooter'
import HeaderChousePlan from '../header/HeaderChousePlan'
import { StudioRegister } from '../../../Studio-Services/Studio_Services'
import Swal from 'sweetalert2'
import logo from "../../../Images/logo_white_web.png"
import { Spinner } from 'react-bootstrap'
import Tooltips from '../../../client-staff/Components/CommanPages/Tooltips'


export const StudioRegistration = () => {
  const [plansId, setPlansId] = useState();
  const [loder, setLoder] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location && location.state && location.state.plan_id) {
      setPlansId(location.state.plan_id);
    }

  }, [location])
  // const plan_id = location.state;

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [getValue, setGetValue] = useState({ email: '', password: '' });
  const [error, setError] = useState({ email: '', password: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGetValue((pre) => ({ ...pre, [name]: value }));
    setError((pre) => ({ ...pre, [name]: '' }));
  };

  // const handlePasswordChange = (event) => {
  //   setPassword(event.target.value);
  // };

  // const validateEmail = () => {
  //   if (!email) {
  //     setEmailError('Email is required');
  //   } else if (!/\S+@\S+\.\S+/.test(email)) {
  //     setEmailError('Email must be a valid email address');
  //   } else {
  //     setEmailError('');
  //   }
  // };

  // const validatePassword = () => {
  //   if (!password) {
  //     setPasswordError('Password is required');
  //   } else if (password.length < 8) {
  //     setPasswordError('Password must be at least 8 characters');
  //   } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.])[A-Za-z\d$@$!%*?&#.]{8,30}/.test(password)) {
  //     setPasswordError('Password must have at least one special character, one capital letter, one small letter, and one number');
  //   } else {
  //     setPasswordError('');
  //   }
  // };

  // useEffect(() => {
  //   validateEmail();
  //   validatePassword();
  // }, [email, password]);

  // const queryParams = new URLSearchParams(plan_id).toString();
  const queryParams = '';

  const handleSubmit = async (event) => {
    localStorage.removeItem('st_userPassword');
    localStorage.removeItem('st_userRegisterData');
    event.preventDefault();
    let isValid = 1;
    const email = getValue.email;
    const password = getValue.password;

    if (!email) { setError((pre) => ({ ...pre, email: 'Email is required' })); isValid = 2; };
    if (email && !/\S+@\S+\.\S+/.test(email)) { setError((pre) => ({ ...pre, email: 'Email must be a valid email address' })); isValid = 2; };
    if (!password) { setError((pre) => ({ ...pre, password: 'Password is required' })); isValid = 3; };
    if (password && !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.])[A-Za-z\d$@$!%*?&#.]{8,30}/.test(password)) { setError((pre) => ({ ...pre, password: 'Password must have at least one special character, one capital letter, one small letter, and one number' })); isValid = 3; };

    if (isValid === 1) {
      setLoder(true);
      const formData = {
        "email": email,
        "password": password,
        "plan": location.state.plan_id
      }
      const resp = await StudioRegister(formData);
      if (resp) {
        setLoder(false);
        localStorage.setItem('st_userRegisterData', JSON.stringify(resp.data.data));
        localStorage.setItem('st_userPassword', JSON.stringify(password));
        Swal.fire({
          html: `
            <img src=${logo} class="img-fluid d-block mx-auto mt-5" alt='' />
            <h4 class="title text-uppercase text-center mt-4 textWhite">Success</h4>
            <p class="text-center my-4 textcolor">
              <small class="textWhite">${resp.data.message}</small>
            </p>
          `,
          showClass: {
            popup: 'custom-dialog-container',
          },
          customClass: {
            confirmButton: 'custom-button custom-button-size modalbtn',
          },
          confirmButtonText: 'Continue',
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirect to a new page
            // navigate('/studio-detail',{state:{Id : businessDetail.business_id ,backurl:currentUrl}});

            // navigate(`/payment-setup?id=${queryParams}`);
            navigate('/paycard-setup', { state: { plan_id: location.state.plan_id } })
          }
        });
      }
      setLoder(false);
    }
  };


  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <HeaderChousePlan />
      <div className="middle-content-wrp" style={{ paddingTop: "120px" }}>
        {/* image layer */}
        <div className="signwrapp-back-layer">
          <img src="assets/images/pictures/watermark_bg.png" />
        </div>
        {/* img layer */}
        <div className="container py-5 relative setminheight d-flex align-items-center">
          <div className="stepsinfo-fill">
            <ul className="steps">
              <li className="completed-step" />
              <li className="completed-step" />
              <li />
            </ul>
          </div>
          <div className="row justify-content-center flex-fill">
            <div className="col-md-6 col-lg-5 col-xl-4">
              <div className="middle-innerbox py-4 px-4">
                <span className="brand-light text-uppercase d-block text-center mt-2">
                  Step 2 of 3
                </span>
                <h3 className="title text-center mt-4">
                  Sign up to start your business account
                </h3>
                <p className="text-center brand-light mb-4 font-16">
                  By creating the account you acknowledge you have the authority to do
                  so on behalf of the business.
                </p>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name='email'
                      className='form-control'
                      value={getValue.email}
                      onChange={handleChange}
                    />
                    <small className='text-danger'>{error.email}</small>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <Tooltips info={'Password at least 8 characters long ,1 Capital letter ,1 number ,1 special character'} />
                    <input
                      type="password"
                      name='password'
                      className='form-control'
                      value={getValue.password}
                      onChange={handleChange}
                    />
                    <small className='text-danger'>{error.password}</small>
                  </div>
                  <button className="btn btn-brandbluegrad mt-4 w-100" type="submit">
                    Continue
                  </button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
      <StaticFooter />
    </>
  )
}
