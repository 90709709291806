import React from 'react'
import https from '../client-staff-https';
import Swal from 'sweetalert2';
import logo from "../Images/logo_white_web.png"
import { errorAlert } from './Alertmsg';

export const PlanList = async () => {
  try {
    const responce = await https.get("web/transaction/plans_list");
    if (responce) {
      if (responce) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const demoContect = async (data) => {
  try {
    const responce = await https.post("/webservices/api/demo", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      } else alert(responce.data.message);
    }
  } catch (error) {
    alert(error);
  }
}

export const login = async (data) => {
  try {
    const resp = await https.post("/webservices/api/login", data);
    if (resp.data.status == 1) {
      const userData = resp.data.data;
   
      localStorage.setItem('Staffid',userData.id);
      localStorage.setItem("userDetail", JSON.stringify(userData));//w_ParentDetail
      localStorage.setItem("lat",resp.data.data.lat) ;
      localStorage.setItem("lang",resp.data.data.lang);
      localStorage.setItem("currentUserId",resp.data.data.id) ;
      localStorage.setItem("parentid",resp.data.data.id) ;
      localStorage.setItem('new_parent_id',resp.data.data.id);
      localStorage.setItem("role_id",resp.data.data.role_id);
      localStorage.setItem('w_loginDetail', JSON.stringify(userData));
      localStorage.setItem('w_parentId', JSON.stringify(userData.id));      
      localStorage.setItem('w_MemberDetail', JSON.stringify(userData.family_member));      
      localStorage.setItem('w_ParentDetail', JSON.stringify(userData));
  
      return resp;
    } else {
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${resp.data.message} </small></p>
        `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    }
  } catch (error) {
    alert(error);
  }
}


// Instructor API

export const get_business_detail = async (data) => {
  try {
    const resp = await https.post("/webservices/instructor/get_business_detail", data);
    if (resp.data.status == 1) {
      localStorage.setItem('bzDetails',JSON.stringify(resp.data.data));
      return resp;
    } else {
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${resp.data.message} </small></p>
        `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    }
  } catch (error) {
    alert(error);
  }
}

export const my_studio_list = async (data) => {
  try {
    const resp = await https.post("/webservices/instructor/my_studio_list", data);
    if (resp.data.status == 1) {
      return resp;
    } else {
      // errorAlert(resp.data.message);
    }
  } catch (error) {
    alert(error);
  }
}

export const API_my_studio_list = async (data) => {
  try {
    const resp = await https.post("/webservices/api/my_studio_list", data);
    if (resp.data.status == 1) {
      return resp;
    } else {
      // errorAlert(resp.data.message);
    }
  } catch (error) {
    alert(error);
  }
}

