

export const taxCalculator = (total, tax) => {
  const res = parseFloat(total * tax) / 100;
  console.log("total--", total, "tax---", tax,"res--",res);
  return res;
}

export const finalAMT = (base, tax1, tax2) => {
  const tx = parseFloat(tax1) + parseFloat(tax2);
  const f = parseFloat(base) + parseFloat(tx);
  return f
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const monthMap = () => {
  const data = {
    'January': 1,
    'February': 2,
    'March': 3,
    'April': 4,
    'May': 5,
    'June': 6,
    'July': 7,
    'August': 8,
    'September': 9,
    'October': 10,
    'November': 11,
    'December': 12
  };
  return data;
}

export const sortByKeyAndRemoveDuplicates = (array) => {
  array.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  // Step 2: Remove duplicate entries based on 'id' field
  const uniqueArray = array.filter((obj, index, self) =>
    index === self.findIndex((t) => t.id === obj.id)
  );

  return uniqueArray;
}


export const adjAmountSubTotal = (amount, discount, tax, qty) => {
  const baseAmt = amount * qty;
  const amt = baseAmt - parseFloat(discount) + parseFloat(tax);
  console.log("--------------------", amt);
  return parseFloat(amt);
};

export const yesNoOption = [
  {label:'Yes',value:"Yes"},
  {label:'No',value:"No"},
]