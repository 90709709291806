import moment from "moment";
import React, { useEffect, useState } from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const PassSlider_New = ({ pass, currentUrl, redirectUrl, businessDl, addFav }) => {

    const cardDetail = pass ? pass : [];
    // const isChecked = checkedPassId || "";
    const navigate = useNavigate();
    const [businessDetail, setBusinessDetail] = useState();

    useEffect(() => {
        if (businessDl) {
            setBusinessDetail(businessDl);
        }
    }, [businessDl])

    const chunks = [];
    for (let i = 0; i < cardDetail.length; i += 2) {
        chunks.push(cardDetail.slice(i, i + 2));
    }

    const settings = {
        prevIcon: <FaLongArrowAltLeft />,
        nextIcon: <FaLongArrowAltRight />,
        interval: 1000000,
        pauseOnHover: true,
        wrap: true,
        touch: true,
        keyboard: true,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            {cardDetail?.length > 0 &&
                <Row className="mt-1 mb-">
                    {cardDetail.map((items, cardIndex) => (
                        <Col lg={4} md={4} key={cardIndex} className="mt-3 mb-2">
                            <div className="available-card" style={{ cursor: 'pointer' }}>
                                <div className="available-blue-card" style={{padding:'1rem 3rem 1rem 1rem'}} onClick={() => redirectUrl(items, '/passes-detail', currentUrl)}>
                                    <div className="row mx-0 ">
                                        <div className="col-4 pass-logo-new">
                                            <img src={businessDetail && businessDetail.logo ? businessDetail.logo : ''} className="img-fluid" />
                                        </div>
                                        <div className="col-8 flex-fill m-auto p-auto passTextwidth ">
                                            <h5 className="white-color font-14 mb-2 mt-0">{items.pass_name}</h5>
                                            <h5 className="white-color font-14 mb-2 mt-0">${items.amount} <span className="font-12"> Plus Tax
                                            </span></h5>
                                        </div>
                                    </div>
                                    <div className="row mt-5 align-items-end">
                                        <div className="col-6 text-left pr-0">
                                            <p className="mb-0 white-color paratitle"> Valid For {items.pass_validity}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="studio-card-select">
                                    {items.favourite == 0 && <i className="fas fa-heart brand-color3 font-22" onClick={() => addFav(items.pass_id, 1)}></i>}
                                    {items.favourite == 1 && <i className="fas fa-heart brand-color2 font-22" onClick={() => addFav(items.pass_id, 0)}></i>}

                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            }

        </>
    )
}
