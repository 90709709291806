import React from 'react'
import './Family.css'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { API_get_switch_user_profile, staff_add_member, staff_edit_member, staff_get_countries, staff_get_member_list, staff_get_member_new_list, staff_get_profile, staff_get_relations, staff_remove_member } from '../../../Static_Services/Staff_Services'
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useEffect } from 'react'
import { InputField } from '../../../sharedComponents/InputField'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import { useRef } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export const Family = () => {

  const [relations, setRelations] = useState();
  const [loder, setLoder] = useState(false);
  const [memberDetail, setMemberDetail] = useState([]);
  let monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const [memberDetail1, setMemberDetail1] = useState({ 'member_name': '', 'dob': '', 'memeber_id': '', 'relative_id': '', 'gender': '' });
  const [error, setError] = useState({ 'member_name': '', 'dob': '', 'memeber_id': '', 'relative_id': '', 'gender': '' });

  const [selectedDate, setSelectedDate] = useState();
  // const [relationIds, setRelationIds] = useState()
  const [show, setShow] = useState(false);
  const [crUser, setCrUser] = useState();

  const navigate = useNavigate();

  const [imgURL, setImgURL] = useState('');
  const [imagePath, setImagePath] = useState(null);
  const fileInputRef = useRef(null);
  const [userinfo, setUserinfo] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [profileDetail, setProfileDetail] = useState();
  const [parentDetail, setParentDetail] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [parentId, setParentId] = useState();

  const [dob, setDob] = useState();
  const [gender, setGender] = useState();


  const getRelationList = async () => {
    const resp = await staff_get_relations()
    if (resp) {
      setRelations(resp.data.data);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('new_parent_id')) {
      setParentId(localStorage.getItem('new_parent_id'));
    }
  }, [localStorage.getItem('new_parent_id')])


  useEffect(() => {
    if (localStorage.getItem('w_loginDetail')) {
      const userInfo = JSON.parse(localStorage.getItem('w_loginDetail'));
      setUserinfo(userInfo);
      setCrUser(userInfo?.id);
    }
  }, [localStorage.getItem('w_loginDetail')])


  const getMemberList = async () => {
    setLoder(true);
    var payload = { "pageid": "1" };
    const resp = await staff_get_member_new_list(payload);
    if (resp) {
      const response = resp.data;
      response.data.map((el) => {
        if (el.dob) {
          var currentDate_date = el.dob.split('-');
          el.new_dob = monthArray[currentDate_date[1] - 1] + ' ' + currentDate_date[2] + ' ' + currentDate_date[0]
        }
      })
      setMemberDetail(response.data);
      localStorage.setItem('w_MemberDetail', JSON.stringify(response.data));
      setLoder(false);
      setShow(false);
    } else {
      setLoder(false);
    }
  }


  const deleteMembers = async (id) => {
    setLoder(true);
    var detail = { "member_id": id };
    const resp = await staff_remove_member(detail);
    if (resp) {
      successAlert(resp.data.message);
      getMemberList();
      setLoder(false);
    } else {
      setLoder(false);
    }
  }

  const getUploadFileDetail = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      var mimeType = event.target.files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        errorAlert("Only images are supported.");
        return;
      }
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const result = e.target.result;
          setImgURL(result);
          setImagePath(file);
        };
      }
    }
  }


  const updateMembers = async () => {
    //   // setLoder(true);
    //   let isValid =1;

    //   if(!memberDetail1.member_name){setError((pre)=>({...pre,member_name:'Full Name  is required'})) ; isValid=2}
    //   if(!selectedDate){setError((pre)=>({...pre,dob:'Date of Birth is required'})) ; isValid=3}
    //   if(!memberDetail1.relationIds){setError((pre)=>({...pre,relationIds:'Please Select Relation'})) ; isValid=4}
    //   if(!memberDetail1.gender){setError((pre)=>({...pre,gander:'Gender  is required'}));isValid=5}

    //   console.log(memberDetail1)
    //   return;
    //   var formdata = new FormData();
    //   formdata.append('fullname', memberDetail1.member_name);
    //   formdata.append('dob', memberDetail1.dob);
    //   formdata.append('relative_id', memberDetail1.relationIds);
    //   formdata.append('member_id', memberDetail1.memeber_id);
    //   formdata.append('gender', memberDetail1.gender);
    //   if (selectedFile) {
    //     formdata.append('image', this.selectedFile);
    //   }
    //   const resp = await staff_edit_member();
    //   if (resp) {
    //     successAlert(resp.data.message);
    //     setLoder(false);
    //     getMemberList();
    //     // $('#btn_close1').trigger('click');
    //     // this.closeModal();
    //   } else {
    //     setLoder(false);
    //   }
  }


  const addMembers = async () => {
    let isValid = 1;
    if (!memberDetail1.member_name) { setError((pre) => ({ ...pre, member_name: 'Full Name  is required' })); isValid = 2 }
    if (!selectedDate) { setError((pre) => ({ ...pre, dob: 'Date of Birth is required' })); isValid = 3 }
    if (!memberDetail1.relative_id) { setError((pre) => ({ ...pre, relative_id: 'Please Select Relation' })); isValid = 4 }
    if (!memberDetail1.gender) { setError((pre) => ({ ...pre, gender: 'Gender  is required' })); isValid = 5 }

    if (isValid === 1) {
      setLoder(true);
      var formdata = new FormData();
      formdata.append('fullname', memberDetail1.member_name);
      formdata.append('dob', selectedDate.toString());
      formdata.append('relative_id', memberDetail1.relative_id);
      formdata.append('image', imagePath);
      formdata.append('gender', memberDetail1.gender);
      const resp = await staff_add_member(formdata);
      if (resp) {
        setMemberDetail1({ 'member_name': '', 'dob': '', 'memeber_id': '', 'relative_id': '', 'gender': '' });
        setSelectedDate('');
        successAlert(resp.data.message);
        setLoder(false);
        setShow(false);
        getMemberList();
      } else {
        setLoder(false);
      }
    }
  }



  const getProfile = async () => {
    setLoder(true);
    let cd;
    let cn;
    let today = new Date();
    let currYear = today.getFullYear();

    const resp = await staff_get_profile()
    if (resp) {
      let response = resp.data;

      countryList.map((el) => {
        if (el.code == response.data.emergency_country_code) {
          cd = el.name + '(' + el.code + ')';
        }
      })

      countryList.map((el) => {
        if (el.code == response.data.country_code) {
          cn = el.name + ' (' + el.code + ')';

        }
      })
      if (response.data.date_of_birth) {
        setDob(response.data.date_of_birth);
      }
      let proDetail = response.data;
      proDetail.onlyCountryCode = response.data.country_code;
      proDetail.emgOnlyCountryCode = response.data.emergency_country_code;
      proDetail.emergency_country_code = cd;
      proDetail.country_code = cn;
      if (proDetail.gender) {
        setGender(proDetail.gender);
      }
      setProfileDetail(proDetail);
      setLoder(false);
    } else {
      setLoder(false)
    }
  }



  const changeImage = () => {
    fileInputRef.current.click();
  }

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setMemberDetail1((pre) => ({ ...pre, [name]: value }));
    setError((pre) => ({ ...pre, [name]: '' }));
  }


  useEffect(() => {
    getMemberList();
    getRelationList();
  }, []);


  const getCountry = async () => {
    let cd = '';
    const resp = await staff_get_countries();
    if (resp) {
      const response = resp.data;
      response.data.map((el) => {
        if (el.name == 'United States') {
          cd = el.name + ' (' + el.code + ')';
        }
      })
      setCountryList(response.data);
      let prDetail = profileDetail;
      if (prDetail) {
        prDetail.emergency_country_code = cd;
      }
      setProfileDetail(prDetail);        // 
    }
  }


  useEffect(() => {
    getCountry();
  }, [])


  const getProfile1 = async () => {
    setLoder(true);
    let cd = '';
    const resp = await API_get_switch_user_profile();
    if (resp) {
      const response = resp.data;
      countryList.map((el) => {
        if (el.code == response.data.emergency_country_code) {
          cd = el.name + ' (' + el.code + ')';
        }
      })
      if (response.data.date_of_birth) {
        setDob(response.data.date_of_birth);
      }
      let ProDetail = response.data;
      ProDetail.onlyCountryCode = response.data.country_code;
      ProDetail.emgOnlyCountryCode = response.data.emergency_country_code;
      // ProDetail.emergency_country_code = cd;
      if (ProDetail.gender) {
        setGender(ProDetail.gender);
      }
      ProDetail.mobile1 = ProDetail.country_code + '  ' + ProDetail.mobile;
      localStorage.setItem('w_loginDetail', JSON.stringify(response.data));
      localStorage.setItem("userDetail", JSON.stringify(response.data));
      setParentDetail(ProDetail);
      getProfile();
      setLoder(false);
      navigate('/profile');
    } else {
      setLoder(false);

    }
    setLoder(false);
  }



  const changeUserProfile = (id) => {
    let inf = userinfo
    inf.id = id;
    localStorage.setItem('w_loginDetail', JSON.stringify(inf));
    localStorage.setItem("userDetail", JSON.stringify(inf))
    getProfile1();
  }

  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />
      <div className="body-grey">
        {/* <!-- Favourite Section --> */}
        <div class="py-5 client-familys">
          <div class="studio-cards">
            <div class="container">
              <div class="row mb-4">
                <div class="col-6">
                  <div class="section-heading mt-3 mt-md-0">
                    <h5 class="title black-color font-w-100 text-uppercase font-16"> My Family </h5>
                  </div>
                </div>
                {/* <!-- end of section-heading --> */}

                <div class="col-6">
                  {crUser === parentId &&
                    <div class="text-right">
                      <button data-toggle="modal" class="btn btn-green-grad px-3" onClick={() => setShow(true)}> Add Member </button>
                    </div>
                  }
                </div>
                {/* <!-- end of col-6 --> */}
              </div>
              {memberDetail.length > 0 &&
                <div class="row">
                  {memberDetail && memberDetail.map((fam) => (
                    <>
                      <div class="col-12 col-sm-6 col-md-4 mb-2" >
                        <div style={{ cursor: 'pointer' }} class={fam?.id === crUser ? "white-bg border-radius6 box-shadow1 p-4 newBr" : "white-bg border-radius6 box-shadow1 p-4"} onClick={() => changeUserProfile(fam?.id)}>
                          <div class="row">
                            <div class="col-3 pr-0 class-detail-slot-box">
                              <div class="profile-img box-shadow1">
                                <img src={fam.image} class="img-fluid" />
                              </div>
                            </div>
                            <div class="col-7">
                              <div class="new-trash-cls">
                                <h5 class="title black-color mb-0">  {fam.member_name}  </h5>
                                <p class="brand-color3 font-12  mb-0 line-height18">  {fam.relation}  </p>
                                <p class="brand-color3 font-12 mb-0 line-height18">  {fam.new_dob}  </p>
                              </div>
                            </div>
                            <div class="col-2">
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              }
            </div>
          </div >
        </div >

        <Modal show={show} className="modal fade" id="addFamily">
          <div class="modal-body white-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5">
            <div className="modal-logo text-center pb-3 pb-sm-2 mb-2">
              <input
                type="file"
                ref={fileInputRef}
                id="file1"
                name="image"
                accept="image/*"
                onChange={(e) => getUploadFileDetail(e)}
                style={{ display: "none", }}
              />
              {!imgURL && <img src="clientassets/img/logo_white.png" className="img-fluid m-0 PopProfileImg" />}
              {imgURL && <img src={imgURL} className="img-fluid m-0 PopProfileImg" />}
              <a href="javascript:void(0);">
                <div className="logo-camera box-shadow1 text-center pt-2">
                  <i
                    className="fas fa-camera brand-color2 font-18"
                    onClick={changeImage}
                  ></i>
                </div>
              </a>
            </div>

            <div class="modal-bottom-content blue-gradient border-radius6 py-3 py-sm-2 px-2">
              <form>
                <div class="form-group profile-change-password">
                  <p class="text-white text-left font-14 mb-0 ml-2"> Full Name </p>
                  <input type="text" class="form-control" onChange={inputHandler} value={memberDetail1.member_name} formControlName="fullname" name='member_name' style={{ color: 'black' }} />
                  <small className='text-danger'>{error.member_name}</small>
                </div>
                <div class="form-group">
                  <p class="text-white text-left font-14 mb-0 ml-2"> Date of Birth  </p>
                  <InputField
                    className={'form-control text-black'}
                    type="date"
                    max={moment().format('YYYY-MM-DD')}
                    onChange={(e) => { setSelectedDate(e.target.value); }}
                    value={selectedDate}
                    errorClass={'text-danger'}
                    error={error.dob}
                  />
                  {/* <small className='text-danger'>{error.dob}</small> */}
                </div >
                {/* < !--New Password-- > */}

                <div class="form-group relative">
                  <p class="text-white text-left font-14 mb-0 ml-2"> Gender  <i class="fas fa-chevron-down"></i></p>
                  <select class="form-control white-color" formControlName="gender" name='gender' onChange={inputHandler} >
                    <option value="" > </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <small className='text-danger'>{error.gender}</small>
                </div >
                <div class="form-group relative">
                  <p class="text-white text-left font-14 mb-0 ml-2"> Relation with me <i class="fas fa-chevron-down"></i></p>
                  <select class="form-control white-color" formControlName="relative_id" onChange={inputHandler} name='relative_id' >
                    <option value="" selected > </option>
                    {relations && relations.length > 0 && relations.map((rl) => (
                      <option value={rl.relative_id}>  {rl.realtion_name} </option>
                    ))}
                  </select >
                  <small className='text-danger'>{error.relative_id}</small>
                </div >
                {/* < !--Confirm Password-- > */}

                <div class="form-group px-3 pt-0 pt-sm-2 mb-0 mb-sm-2 d-flex" style={{ justifyContent: "center" }}>
                  {!memberDetail1.memeber_id &&
                    <button type="button" class="btn text-uppercase" onClick={() => addMembers()} >  Save </button >}
                  {memberDetail1.memeber_id &&
                    <button type="button" class="btn text-uppercase" onClick={() => updateMembers()}>  Update </button >}
                  <button type="button" data-dismiss="modal" id="btn_close1" class="btn text-uppercase ml-5" onClick={() => setShow(false)}> close </button >
                </div >
              </form >
            </div >
          </div >
        </Modal >
      </div >
      <Innerfooter />
    </>
  )
}
