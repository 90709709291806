import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { InputField } from '../../../../../sharedComponents/InputField'
import { SharedSelect } from '../../../../../sharedComponents/SharedSelect'
import { TextArea } from '../../../../../sharedComponents/TextArea'
import { SharedButton } from '../../../../../sharedComponents/Button'
import { add_passes, class_list, get_pass_type } from '../../../../../Studio-Services/Studio_Services'
import moment from 'moment'
import Swal from 'sweetalert2'
import logo from "../../../../../Images/logo_white_web.png"
import { InputTypeTax } from '../../../../../sharedComponents/InputTypeTax'
import { AddPassMultiSelect } from './AddPassMultiSelect'
import { yesNoOption } from '../../../../../sharedComponents/MyHelperFunction'
import Tooltips from '../../../../../client-staff/Components/CommanPages/Tooltips'

export const AddPassForm = ({ passList }) => {
    const [loder, setLoder] = useState(false);
    const [passOp, setPassOp] = useState([]);
    const [tax1ops, setTax1ops] = useState();
    const [tax2ops, setTax2ops] = useState();
    const [searchTitle, setSearchTitle] = useState();
    const [passtypeOPs, setPasstypeOPs] = useState();
    const [ageHande, setAgeHande] = useState();
    const [clsOPT, setClsOPT] = useState([]);
    const [clear, setClear] = useState(0);
    const [expDay, setExpDay] = useState()
    const [selectedOption, setSelectedOption] = useState();
    const [showEXP, setShowEXP] = useState();
    const [showRenew, setShowRenew] = useState();

    const [skillsdata, setSkillsdata] = useState();

    useEffect(() => {
        if (skillsdata) {
            setError((pre) => ({ ...pre, class_details: "" }))
        }
        if (ageHande) {
            setError((pre) => ({ ...pre, age_restriction: "" }))
        }
    }, [ageHande, skillsdata])

    const getPasstype = async () => {
        const fdata = { "pass_subcat": "" };
        const resp = await get_pass_type(fdata);
        if (resp) {
            let pty = [];
            (resp.data.data).map((e) => {
                pty.push({
                    value: e.pass_type_id, label: e.pass_type
                })
            })
            setPassOp(pty);
        }
    }

    const getClassList = async () => {
        const fData = {
            "pageid": 1,
            "business_id": localStorage.getItem("business_id")
        }
        const resp = await class_list(fData);
        if (resp) {
            const cls = [];
            (resp.data.data).map((e) => {
                cls.push({
                    id: e.class_id, label: e.class_name
                })
            })
            setClsOPT(cls);
        }
    }


    const passSelector = async (e) => {
        let value = e.target.value;
        if (value === "S7MyslIyNFCyBgA=" || value === "S7MytFIyVLIGAA==") {
            setShowEXP(value);
        } else setShowEXP();

        setError((pre) => ({ ...pre, pass_type: "" }));
        if (value === "S7MysVIyNDA2ULIGAA==") {
            setShowRenew(value);
            value = "S7MytFIyVLIGAA=="
        }else{
            setShowRenew();
        }
        const fData = {
            "pass_subcat": value
        }
        const resp = await get_pass_type(fData);
        if (resp) {
            setSelectedOption(resp.data.data);
            let ops = [];
            (resp.data.data).map((e) => {
                ops.push({
                    value: e.pass_type_id, label: e.pass_type
                })
            })

            setPasstypeOPs(ops);
            console.log(value);
            if (value === "S7MyslIyNFCyBgA=") {
                setSearchTitle(<>Number Of Days</>);
            } else if (value === "S7MytFIyVLIGAA==") {
                setSearchTitle(<>Number Of Classes</>);
            } else if (value === "S7MyslIyNleyBgA=") {
                setSearchTitle(<>Number Of Month</>);
            }
        }
    }

    const opyesno = [
        { value: "yes", label: <>Yes</> },
        { value: "no", label: <>No</> },
    ]

    const ageOPT = [
        { value: "Over", label: <>Over </> },
        { value: "Under", label: <>Under </> },
        { value: "None", label: <>None </> },
    ]
    useEffect(() => {
        getPasstype();
        getClassList();
    }, [])

    const tax1Handler = (e) => {
        const value = e.target.value;
        setError((pre) => ({ ...pre, tax1: "" }));
        setTax1ops(value);
    }
    const tax2Handler = (e) => {
        const value = e.target.value;
        setError((pre) => ({ ...pre, tax2: "" }));
        setTax2ops(value);
    }


    const [error, setError] = useState({
        pass_name: "", pass_type: "", pass_sub_type: "", purchase_date: "", passexpiry: "", tax1: "", tax2: "",
        tax1_rate: "", tax2_rate: "", amount: "", is_client_visible: "", is_one_time_purchase: "", description: "",
        notes: "", age_restriction: "", age_over_under: "", class_details: ""
    })

    const errorHandler = (e) => {
        const { name, value } = e.target;
        if (name === 'pass_sub_type' && (showEXP === "S7MyslIyNFCyBgA=" || showEXP === "S7MytFIyVLIGAA==")) {
            const resp = selectedOption.filter(opt => opt.pass_type_id === value);
            // setExpDay(resp[0].pass_type);
        }
        setError((pre) => ({ ...pre, [name]: "" }));
        if (name === 'passexpiry') {
            setExpDay(value);
        }
    }


    const submitHandler = async (e) => {
        e.preventDefault();

        const fData = new FormData(e.target);
        const fValue = Object.fromEntries(fData.entries());
        let isValid = 1;

        const class_details = [];
        if (skillsdata) { skillsdata.map((e) => { class_details.push(e.id,) }) }

        let passexpiry = '';

        // passexpiry = fValue.passexpiry;
        if (fValue.pass_sub_type != '' && fValue.pass_sub_type == "S7MyslIyNlOyBgA=") {
            passexpiry = 30;
        } else if (fValue.pass_sub_type != '' && fValue.pass_sub_type == "S7MyslIyNlayBgA=") {
            passexpiry = 90;
        } else if (fValue.pass_sub_type != '' && fValue.pass_sub_type == "S7MyslIyNlGyBgA=") {
            passexpiry = 180;
        } else { passexpiry = fValue.passexpiry }


        const autoAdd = fValue.autoAdd;       

        const pass_name = fValue.pass_name;

        const class_type = "1";
        let pass_type = fValue.pass_type;
        const pass_sub_type = fValue.pass_sub_type;
        const purchase_date = fValue.purchase_date;
        const tax1 = tax1ops;
        const tax2 = tax2ops;
        let tax1_rate = fValue.tax1_rate;
        let tax2_rate = fValue.tax2_rate;
        const amount = fValue.amount;
        const is_client_visible = fValue.is_client_visible;
        const is_one_time_purchase = fValue.is_one_time_purchase;
        const description = fValue.description;
        const notes = fValue.notes || 'none';
        const age_restriction = fValue.age_restriction;
        const age_over_under = fValue.age_over_under;
        const service_type = "1";
        const service_id = "";
        const is_recurring_billing = "no";
        const cls_details = class_details;

        if (!pass_name) { setError((pre) => ({ ...pre, pass_name: "* Required" })); isValid = 2; }
        if (!pass_type) { setError((pre) => ({ ...pre, pass_type: "* Required" })); isValid = 3; }
        if (!pass_sub_type) { setError((pre) => ({ ...pre, pass_sub_type: "* Required" })); isValid = 4; }
        if (!purchase_date) { setError((pre) => ({ ...pre, purchase_date: "* Required" })); isValid = 5; }
        if (!passexpiry) { setError((pre) => ({ ...pre, passexpiry: "* Required" })); isValid = 6; }

        if (!tax1) { setError((pre) => ({ ...pre, tax1: "* Required" })); isValid = 7; }
        if (!tax2) { setError((pre) => ({ ...pre, tax2: "* Required" })); isValid = 9; }

        if (tax1 == "yes") { if (!tax1_rate) { setError((pre) => ({ ...pre, tax1_rate: "* Required" })); isValid = 8; } }
        if (tax2 == "yes") { if (!tax2_rate) { setError((pre) => ({ ...pre, tax2_rate: "* Required" })); isValid = 10; } }

        if (tax1 == "no") { tax1_rate = 0; }
        if (tax2 == "no") { tax2_rate = 0; }


        if (!amount) { setError((pre) => ({ ...pre, amount: "* Required" })); isValid = 11; }
        if (!is_client_visible) { setError((pre) => ({ ...pre, is_client_visible: "* Required" })); isValid = 12; }
        if (!is_one_time_purchase) { setError((pre) => ({ ...pre, is_one_time_purchase: "* Required" })); isValid = 13; }
        if (!description) { setError((pre) => ({ ...pre, description: "* Required" })); isValid = 14; }
        if (!notes) { setError((pre) => ({ ...pre, notes: "* Required" })); isValid = 2; }
        if (!age_restriction) { setError((pre) => ({ ...pre, age_restriction: "* Required" })); isValid = 15; }
        if (age_restriction !== "None") {
            if (!age_over_under) { setError((pre) => ({ ...pre, age_over_under: "* Required" })); isValid = 16; }
        }
        if (cls_details.length === 0) { setError((pre) => ({ ...pre, class_details: "* Required" })); isValid = 17; }


        if (isValid === 1) {
            setLoder(true);
            let is_recurring_billing = ""
            if (pass_type === 'S7MyslIyNleyBgA=') {
                is_recurring_billing = "yes"
            } else if (fValue.pass_type === 'S7MysVIyNDA2ULIGAA==') {
                pass_type = 'S7MytFIyVLIGAA==';
                is_recurring_billing = "yes"
            } else { is_recurring_billing = "no" }

            const finalData = {
                "pass_name": pass_name,
                "class_type": "1",
                "pass_type": pass_type,
                "pass_sub_type": pass_sub_type,
                "purchase_date": moment(purchase_date).unix(),
                "passexpiry": passexpiry,
                "tax1": tax1ops,
                "tax2": tax2ops,
                "tax1_rate": tax1_rate,
                "tax2_rate": tax2_rate,
                "amount": amount,
                "is_client_visible": is_client_visible,
                "is_one_time_purchase": is_one_time_purchase,
                "description": description,
                "notes": notes,
                "age_restriction": age_restriction,
                "age_over_under": age_over_under,
                "service_type": 1,
                "service_id": "",
                "is_recurring_billing": is_recurring_billing,
                "class_details": class_details,
                "autoAdd": autoAdd,
            }

            const resp = await add_passes(finalData);
            if (resp) {
                setLoder(false);
                passList();
                e.target.reset();
                setClear(1);
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                        <h4 class="title text-uppercase text-center mt-4 textWhite">
                        SUCCESS
                    </h4>
                    <p class="text-center my-4 textcolor">
                    <small class="textWhite">
                    ${resp.data.message} </small></p>
                    `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                });
            } else {
                setLoder(false);
            }
        }
        setLoder(false);
    }




    return (
        <>
            {loder && <div className='MainLoader'><Spinner animation="border" role="status" className='Loader'></Spinner></div>}
            <section>
                <h5 className="mt-5 mb-4">Add Passes</h5>
                <Form onSubmit={submitHandler} autoComplete='off'>
                    <Row className="mt-3">
                        <Col>
                            <InputField
                                type={"text"}
                                name={"pass_name"}
                                error={error.pass_name}
                                onChange={errorHandler}
                                lable={"Pass Name"} />
                        </Col>
                        <Col>
                            <SharedSelect
                                options={passOp}
                                onChange={passSelector}
                                name={"pass_type"}
                                error={error.pass_type}
                                lable={"Type of Pass"} />
                        </Col>
                        {searchTitle ?
                            <Col>
                                <SharedSelect
                                    options={passtypeOPs}
                                    name={"pass_sub_type"}
                                    error={error.pass_sub_type}
                                    onChange={errorHandler}
                                    lable={searchTitle} />
                            </Col>
                            : ""}
                        <Col>
                            <InputField
                                type={"date"}
                                name={"purchase_date"}
                                error={error.purchase_date}
                                lable={"Date available for purchase"}
                                onChange={errorHandler}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <InputTypeTax
                                name={"amount"}
                                type={"number"}
                                minLength={'0'}
                                error={error.amount}
                                onChange={errorHandler}
                                lable={"Amount (In $)"} />
                        </Col>

                        <Col>
                            <SharedSelect
                                options={opyesno}
                                name={"is_client_visible"}
                                error={error.is_client_visible}
                                onChange={errorHandler}
                                lable={"Visible to client"} />
                        </Col>
                        <Col>
                            <SharedSelect
                                options={opyesno}
                                name={"is_one_time_purchase"}
                                error={error.is_one_time_purchase}
                                onChange={errorHandler}
                                lable={"One time purchase only"} />
                        </Col>

                    </Row>

                    <Row className="mt-3">
                        {showEXP &&
                            <Col>
                                <InputField
                                    minLength={"0"}
                                    name={"passexpiry"}
                                    type={"number"}
                                    value={expDay}
                                    error={error.passexpiry}
                                    onChange={errorHandler}
                                    lable={"Days after purchase it expires"} />
                            </Col>}
                        {showRenew &&
                            <Col>
                                <InputField
                                    minLength={"0"}
                                    name={"passexpiry"}
                                    type={"number"}
                                    value={expDay}
                                    error={error.passexpiry}
                                    onChange={errorHandler}
                                    lable={<><span>Days after purchase it </span><u>Renew</u></>} />
                            </Col>}
                        <Col>
                            <SharedSelect
                                options={opyesno}
                                name={"tax1"}
                                onChange={tax1Handler}
                                error={error.tax1}
                                lable={"Tax 1 Applicable"} />
                        </Col>
                        {tax1ops === "yes" ?
                            <Col>
                                <InputTypeTax
                                    name={"tax1_rate"}
                                    type={"number"}
                                    error={error.tax1_rate}
                                    minLength={"0"}
                                    onChange={errorHandler}
                                    lable={"Tax 1 Rate %"} />
                            </Col>
                            : ""}
                        <Col>
                            <SharedSelect
                                options={opyesno}
                                name={"tax2"}
                                onChange={tax2Handler}
                                error={error.tax2}
                                lable={"Tax 2 Applicable"} />
                        </Col>

                        {tax2ops === "yes" ?
                            <Col>
                                <InputTypeTax
                                    name={"tax2_rate"}
                                    type={"number"}
                                    error={error.tax2_rate}
                                    onChange={errorHandler}
                                    minLength={"0"}
                                    lable={"Tax 2 Rate %"} />
                            </Col>
                            : ""}

                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <TextArea
                                name={"description"}
                                error={error.description}
                                onChange={errorHandler}
                                lable={"Description"} />
                        </Col>
                        <Col>
                            <TextArea
                                name={"notes"}
                                error={error.notes}
                                onChange={errorHandler}
                                lable={"Notes"} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <SharedSelect
                                options={ageOPT}
                                name={"age_restriction"}
                                onChange={(e) => setAgeHande(e.target.value)}
                                error={error.age_restriction}
                                lable={"Age Restriction"} />
                        </Col>
                        {ageHande !== "None" ?
                            <Col>
                                <InputField
                                    type={"number"}
                                    minLength={"0"}
                                    name={"age_over_under"}
                                    error={error.age_over_under}
                                    onChange={errorHandler}
                                    lable={"What age over/under"}
                                />
                            </Col>
                            : ""}
                        <Col></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <AddPassMultiSelect
                                labelText="Class"
                                setSkillsdata={setSkillsdata}
                                options={clsOPT}
                                clear={clear}
                                setClear={setClear}
                            />
                            <small className='text-danger'>{error.class_details}</small>
                        </Col>
                        <Col>
                            <SharedSelect
                                options={yesNoOption}
                                name={"autoAdd"}
                                lable={"Auto Add"} />
                            
                        </Col>
                        <Col><Tooltips info={'Auto Add feature will automatically add this pass to new clients after they accept the T&C’s / Waiver and become a client. '} /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <SharedButton
                                title={"Save"}
                                className={"btn btn-primary w-100"}
                                type={"submit"}
                                variant={"primary"}
                            />
                        </Col>
                        <Col>
                            <SharedButton
                                href={"/add-things"}
                                title={"Done with passes"}
                                className={"btn btn-outline w-100"}
                                type={"button"}
                                variant={"outline"}
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                </Form>
            </section>
        </>
    )
}
