import React from 'react'
// import './ClientclassNameisDetails.css'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { SubHeader } from '../../Components/sub-header/SubHeader'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import moment from 'moment'
import { Modal, Spinner } from 'react-bootstrap'
import { API_class_details, API_passes_status_change, ageCalculation, favourite, submitQuestionnaire } from '../../../Static_Services/Staff_Services'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { ClientCardCarousal } from '../../Components/CommanPages/ClientCardCarousal'
import { PassSlider } from './PassSlider'
import Swal from 'sweetalert2'
import { PassSlider_New } from './PassSlider_New'

export const ClientclassNameisDetails = () => {
  const [currentUrl, setCurrentUrl] = useState();
  const navigate = useNavigate();
  const [loder, setLoder] = useState(false);
  const location = useLocation();
  const [userInfo, setUserInfo] = useState();
  const [businessDetail, setBusinessDetail] = useState();
  const [iD, setID] = useState();
  // const [ageal,setAgeal] = useState();
  const [punchPassDetail, setPunchPassDetail] = useState([]);
  const [timePassDetail, setTimePassDetail] = useState([])
  const [recurringPassDetail, setRecurringPassDetail] = useState([]);
  const [classData, setClassData] = useState([]);
  const [instrustorData, setInstrustorData] = useState([]);
  const [passData, setPassData] = useState([]);
  const [classStatus, setClassStatus] = useState();
  const [customerDetail, setCustomerDetail] = useState([]);
  const [purchasedPassData, setPurchasedPassData] = useState([]);
  const [passID, setPassID] = useState();
  const [passSelectModal, setPassSelectModal] = useState(false);

  const [isWaver, setIsWaver] = useState(0);



  const [q4_answer2, setQ4_answer2] = useState([{ 'id': 1, 'checked': false, 'text': 'Cold or flu like symptoms: Headache, Fever, Cough' }, { 'id': 3, 'checked': false, 'text': 'Chest pain' }, { 'id': 2, 'checked': false, 'text': 'Difficulty breathing' }])
  const [q4_answer1, setQ4_answer1] = useState([{ 'id': 1, 'checked': false, 'text': 'Cold or flu like symptoms: Headache, Fever, Cough' }, { 'id': 3, 'checked': false, 'text': 'Chest pain' }, { 'id': 2, 'checked': false, 'text': 'Difficulty breathing' }])

  const [q4_answer, setQ4_answer] = useState([{ 'id': 1, 'checked': false, 'text': 'Cold or flu like symptoms: Headache, Fever, Cough' }, { 'id': 3, 'checked': false, 'text': 'Chest pain' }, { 'id': 2, 'checked': false, 'text': 'Difficulty breathing' }])

  const [clsID, setClsID] = useState();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('w_loginDetail'))) {
      setUserInfo(JSON.parse(localStorage.getItem('w_loginDetail')));
    }
    if (JSON.parse(localStorage.getItem('w_BusinessDetail'))) {
      setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
    }

    if (location.pathname) {
      setCurrentUrl(location.pathname);
    }
    const navData = location.state;
    if (navData && navData.Id) {
      localStorage.setItem('w_ClassId', JSON.stringify(navData.Id));
      setID(navData.Id);
    }
    if (localStorage.getItem('w_ClassId')) {
      setID(JSON.parse(localStorage.getItem('w_ClassId')));
    }
  }, [])


  const getClaseDetail = async () => {

    let send_date = moment().format('YYYY-MM-DD');
    if (iD.currentdate) {
      send_date = iD.currentdate;
    }
    setLoder(true);
    const detail = { "pageid": "1", "business_id": businessDetail.business_id, "class_id": iD.id, "select_dt": send_date, "schedule_id": iD.schedule_id };
    const resp = await API_class_details(detail);
    if (resp) {
      let response = resp?.data;

      const waiver = response?.data?.waiver;
      setIsWaver(waiver);
      // Date Conversion UTC
      response.data.start_date_utc_new = moment.unix(response.data.start_date_utc).format('MMM DD YYYY');
      response.data.end_date_utc_new = moment.unix(response.data.end_date_utc).format('MMM DD YYYY');
      // time conversion

      response.data.from_time_new = moment.unix(response.data.from_time_utc).format('hh mm A');
      response.data.to_time_new = moment.unix(response.data.to_time_utc).format('hh mm A');

      if (response?.data?.customer_details?.length != 0) {
        response.data.customer_details.filter(function (el) {
          el.Age = (el.date_of_birth == 'XXXX-XX-XX') ? 'XXXX-XX-XX' : ageCalculation(el.date_of_birth);
        })
      }
      if (response?.data?.instructor_details?.length != 0) {
        response.data.instructor_details.map((el) => {
          el.from_time_new = moment.unix(el.from_time_utc).format('hh mm A');
          el.to_time_new = moment.unix(el.to_time_utc).format('hh mm A');
        })
      }

      if (response?.data?.passes_details?.length != 0) {
        let ageal = false;
        response.data.passes_details.map((el) => {
          el.start_date_utc_new = moment.unix(el.start_date_utc).format('MMM DD YYYY');
          el.end_date_utc_new = moment.unix(el.end_date_utc).format('MMM DD YYYY');

          if (el.age_restriction == 'Over') {
            if (userInfo.age >= el.age_over_under) {
              // setAgeal(true);
              ageal = true;
            } else {
              // setAgeal(false);
              ageal = false;
            }
          } else if (el.age_restriction == 'Under') {
            if (userInfo.age < el.age_over_under) {
              // setAgeal(true);
              ageal = true;
            } else {
              // setAgeal(false);
              ageal = false;
            }
          } else {
            ageal = true;
            //   setAgeal(true);
          }
          el.ageallow = ageal;
          if (el.pass_type_name == 'Punch Card') {
            let ppD = [];
            ppD.push(el);
            setPunchPassDetail(ppD);
          } else if (el.pass_type_name == 'Time Frame') {
            let tmD = [];
            tmD.push(el);
            setTimePassDetail(tmD);
          } else {
            let rrD = [];
            rrD.push(el);
            setRecurringPassDetail(rrD);
          }
        })
      }

      if (response?.data?.my_passes_details?.length != 0) {
        response?.data?.my_passes_details.map((el) => {
          el.start_date_utc_new = moment.unix(el.start_date_utc).format('MMM DD YYYY');
          el.end_date_utc_new = moment.unix(el.end_date_utc).format('MMM DD YYYY');
          el.checked = false;
        })
      }
      setClassData(response?.data);
      setInstrustorData(response.data.instructor_details);
      setPassData(response.data.passes_details);
      let PursedPassData = response?.data?.my_passes_details;
      setPurchasedPassData(PursedPassData)
      const pId = PursedPassData;
      if (response?.data?.my_passes_details?.length != 0) {
        let PsID = pId[0].pass_id;
        setPassID(PsID);
      }
      if (PursedPassData?.length != 0 && response.data.class_status == 0) {
        setClassStatus(1);
        // classStatus = 1;
      } else {
        setClassStatus(response?.data?.class_status)
        // classStatus = response.data.class_status;
      }
      setCustomerDetail(response?.data?.customer_details);
      setLoder(false);
    } else {
      setLoder(false);
      // this.loading = false;
    }
  }

  const addFav = async (id, status) => {
    setLoder(true);
    let detail = { "service_type": "2", "service_id": id, "status": status };
    const resp = await favourite(detail);
    if (resp) {
      successAlert(resp.data.message);
      setLoder(false);
      getClaseDetail();
    } else {
      setLoder(false);
    }
  }

  const changePassStatus1 = async (data, types, pass_myid = '') => {
    setLoder(true);
    let pssid = passID;
    if (!passID) {
      pssid = pass_myid;
    }
    let detail = { "service_type": types, "service_id": iD.id, "passes_status": data, "schedule_id": classData.schedule_id, 'pass_id': pssid, 'attendance_id': classData.attendance_id };
    const resp = await API_passes_status_change(detail);
    if (resp) {
      successAlert(resp.data.message);
      if (data == 'cancel') {
        if (types == "1") {
          navigate('/my-classes');
        } else {
          navigate('/workshops');
        }
      }
      getClaseDetail();
      getCovidInfo(userInfo.id);
      setLoder(false);
      setPassSelectModal(false);
    } else {
      setLoder(false);
    }
  }

  const redirectUrl = (id, url, urlcurrent) => {
    if (urlcurrent) {
      if (id.ageallow) {
        var detail = { 'id': id.pass_id, 'user_booking_id': id.user_booking_id };
        navigate(url, { state: { Id: detail } });
      } else {
        errorAlert('You are not eligible');
      }
    } else {
      navigate(url, { state: { Id: id } });
    }
  }

  const changePassStatus = (data, types) => {
    setLoder(true);

    if (purchasedPassData?.length == 0) {
      errorAlert("Please purchase the pass");
      window.scroll(0, 300);
      setLoder(false);
      return false;
    }

    if (data == 'singup') {
      // if(this.PurchasedPassData.length == 1){
      //   this.PassID = this.PurchasedPassData[0].pass_id;
      //   this.loading = false;
      // } else{

      //setTimeout(function(){

      //   $('#passselect').trigger('click');

      //}, 1000);
      setLoder(false);

      //}
    } else {
      changePassStatus1(data, types);
    }
  }


  const changeSelection = (event) => {
    let q4answers = [];
    let subcatText = '';
    this.q4_answer.map((value, index) => {
      if (value.checked) {
        if (subcatText) {
          subcatText = value.id + "," + subcatText;
        }
        else {
          subcatText = value.id;
        }
      }
    });
    q4answers.push(subcatText);
    setQ4_answer(q4answers);
  }


  const getPassId = (event) => {
    purchasedPassData.filter(function (el) {
      if (el.pass_id == event.target.id) {
        el.checked = event.target.checked;
      }
    })
    setPassID(event.target.id);
  }

  const getCovidInfo = (userid) => {
    // var q4_sheet = this.q4_answer2, anser_sheet = [];
    // var detail = { "userid": userid, "business_id": this.businessDetail.business_id, "class_id": this.ID.id, };
    // var url = 'superadmin/webservices/api/getUserQuestionnaire';
    // this.commonservice.PostApi(detail, url).subscribe(((response: any) => {
    //   if (response.status == 1) {
    //     this.QuestionShhet = response.data;
    //     this.QuestionShhet.covid_status = response.danger_status;
    //     if (response.data) {
    //       response.data.filter(function (el) {
    //         if (el.question_id == '4') {
    //           anser_sheet = el.question_answer;
    //         }
    //       })
    //       anser_sheet.filter(function (el) {
    //         q4_sheet.filter(function (ell) {
    //           if (ell.id == el) {
    //             ell.checked = true;
    //           }
    //         })
    //       })
    //     }
    //     this.q4_answer1 = q4_sheet;
    //   }
    // }))

  }

  const SubmitCovidInfo = async () => {
    setLoder(true);
    // if (!this.q1_answer) {
    //   this.commonservice.dangerAlert('Question 1-3 are mandatory to answer')
    //   return false;
    // }
    // if (!this.q2_answer) {
    //   this.commonservice.dangerAlert('Question 1-3 are mandatory to answer')
    //   return false;
    // }
    // if (!this.q3_answer) {
    //   this.commonservice.dangerAlert('Question 1-3 are mandatory to answer')
    //   return false;
    // }
    // var detail = {
    //   "userid": userInfo.id, "business_id": businessDetail.business_id, "class_id": clsID.id,
    //   "questionnaire": [
    //     {
    //       "question_id": "1",
    //       "question_answer": '0'
    //     },
    //     {
    //       "question_id": "2",
    //       "question_answer": '0'
    //     },
    //     {
    //       "question_id": "3",
    //       "question_answer": '0'
    //     },
    //     {
    //       "question_id": "4",
    //       "question_answer": []
    //     }
    //   ]
    // };
    // const response = await submitQuestionnaire(detail);

    // if (response) {
    //   setLoder(false);
    //   changePassStatus('singup', '1');
    // $('#btn_close21').trigger('click')
    // this.closeModal();
    // this.submitted = false;
    // } else {
    //   errorAlert(response.data.message);
    // }
  }

  const CheckInfo = () => {
    // if (isWaver != 1) {
    //   Swal.fire("Please make sure to agree with terms and conditions before proceeding. Are you agree with T & C ?");
    //   return false;
    // }
    if (classStatus == 0) {
      errorAlert('Please purchase the pass');
      window.scroll(0, 1500);
    } else {
      if (purchasedPassData && purchasedPassData?.length === 1) {
        const pass_id = purchasedPassData[0].pass_id;
        setPassID(pass_id);
        changePassStatus1('singup', '1', pass_id);

      } else {
        setPassSelectModal(true);
      }


      // $('#covidForm').trigger('click');
    }
  }
  const makeActive = () => {

  }

  useEffect(() => {
    if (businessDetail) {
      getClaseDetail();
    }
  }, [businessDetail])
  return (
    <>
      {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
      <InnerHeader />
      <div className="body-grey">
        <div className="studio-details client-home-inner">
          <div className="container">
            <DashboardToSectionDetails />
            <div className="text-right mb-3">
              <button type="button" className="btn btn-blue-grad px-2 text-uppercase"
                onClick={() => window.history.back()} >Back </button>
            </div>
          </div>
          {/* <!-- end of container --> */}

          <div className="client-class-inner mb-4">
            <div className="container">
              <div className="row" >
                <div className="col-12 col-md-4 mb-4" >
                  <p className="black-color font-w-100 text-uppercase font-20"> classes </p>
                  <div className="white-bg box-shadow1 border-radius6 p-3 mt-3 text-center fixed-hight">
                    <div className="row">
                      <div className="col-2 mb-3 ">
                        <img src={businessDetail && businessDetail.logo} className="new-log-img" />
                      </div >
                      <div className="col-8 text-left mb-3">
                        <p className="black-color font-w-100 text-uppercase font-20 ml-2 ">{classData.class_name}  </p>
                      </div>
                      {/* <div className="col-2 text-right">
                        {classData && classData.covid_info == 1 &&
                          <div >
                            {classData.covid_status == 0 &&
                              <button type="button" className="infotab text-success"
                                data-toggle="modal" data-target="#Infocovid19"><i
                                  className="fas fa-info-circle"></i></button>}
                            {classData.covid_status == 1 &&
                              <button type="button" className="infotab text-danger"
                                data-toggle="modal" data-target="#Infocovid19"><i
                                  className="fas fa-info-circle"></i></button>}
                          </div>
                        }
                      </div> */}
                    </div>
                    {classData.waiting_status == 0 &&
                      <div>
                        {classData.class_end_status == 2 &&
                          <div className="d-flex align-items-center justify-content-center flex-wrap mt-4" >
                            <button type="button" className="btn btn-green-grad px-5 text-uppercase"> Class Finished
                            </button>
                          </div>
                        }
                        {classData.class_end_status != 2 &&
                          <div className="d-flex align-items-center justify-content-center flex-wrap mt-4" >
                            {(classStatus == 0 || classStatus == 1) &&
                              <button type="button" className="btn btn-blue-grad px-5 text-uppercase" onClick={() => CheckInfo()} > sign-up </button>}
                            <button type="button" Id="covidForm" style={{ display: "none" }} className="btn btn-blue-grad px-5 text-uppercase" data-toggle="modal" data-target="#covid19" data-backdrop="static" data-keyboard="false">  </button>
                            {classStatus == 2 &&
                              <button type="button" className="btn btn-blue-grad px-5 text-uppercase"
                                onClick={() => changePassStatus1('checkin', '1')}> Check-In </button>}
                            {classStatus == 3 &&
                              <button type="button" className="btn btn-green-grad px-5 text-uppercase"> Checked-In </button>}
                            {classStatus == 4 &&
                              <button type="button" className="btn btn-green-grad px-5 text-uppercase"> Wait List - {classData.waitng_list_no} </button>}
                            {(classStatus == 2 || classStatus == 4) &&
                              <button className="btn btn-danger px-3 text-uppercase infobtn" onClick={() => changePassStatus1('cancel', '1')}> <i className="fas fa-times"></i> </button>}

                            <button type="button" id="passselect"
                              data-toggle="modal" data-target="#PassSelectModal" data-backdrop="static" data-keyboard="false" style={{ display: "none" }}
                              onClick={() => makeActive()}
                            ></button>
                          </div>
                        }
                      </div>
                    }

                    {classData.waiting_status == 1 &&
                      <div className="d-flex align-items-center justify-content-center flex-wrap mt-4" >
                        <button type="button" className="btn btn-blue-grad px-5 text-uppercase"
                          onClick={() => CheckInfo()}
                        > Join WaitList </button>
                        <button type="button" Id="covidForm" style={{ display: "none" }} className="btn btn-blue-grad px-5 text-uppercase" data-toggle="modal" data-target="#covid19" data-backdrop="static" data-keyboard="false">  </button>

                      </div>
                    }
                    {classData.waiting_status == 2 &&
                      <div className="d-flex align-items-center justify-content-center flex-wrap mt-4" >
                        <button type="button" className="btn btn-blue-grad px-5 text-uppercase"> Class Full </button>
                      </div>
                    }

                  </div>
                </div>
                <div className="col-12 col-md-4 mb-4">
                  <p className="black-color font-w-100 text-uppercase font-20"> other details </p>
                  <div className="white-bg box-shadow1 border-radius6 p-3 mt-3 fixed-hight">
                    <div className="row">
                      <div className="col-6 col-md-6">
                        <div className="text-left">
                          <p className="font-14 black-color mb-0"> Date </p>
                          <h5 className="title black-color mb-0">{classData.start_date_utc_new}  </h5>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 border-left">
                        <div className="text-center">
                          <p className="font-14 black-color mb-0"> Start Time</p>
                          <h5 className="title black-color mb-0">{classData.from_time_new}  </h5>
                        </div>
                      </div>
                    </div>
                    {/* <!-- end of row --> */}
                    <div className="row mt-2">
                      <div className="col-6 col-md-6">
                        <div className="text-left">
                          <p className="font-14 black-color mb-0"> Duration </p>
                          <h5 className="title black-color mb-0">{classData.duration}</h5>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 border-left">
                        <div className="text-center mt-3">
                          {/* {classStatus == 3 ?
                            <h6 className="title black-color mb-0">
                              <Link to={classData.location_url} target="_blank" title={classData.location_url}>
                              Location
                              </Link></h6>
                            :
                            <h6 className="title black-color mb-0"><Link to={classData.location_url} target="_blank" title={classData.location_url}>Location</Link></h6>
                          } */}

                          {/* <p className="font-14 black-color mb-0"> Capacity </p>
                          <h5 className="title black-color mb-0">
                            {classData.capacity_used} /
                            {classData.total_capacity}  </h5> */}
                        </div>
                      </div>
                    </div>
                    {/* <!-- end of row --> */}
                    {/* <div className="row mt-2 ">
                      <div className="col-12 col-md-12">
                        <div className="text-left d-flex"> */}
                    {/* <p className="font-14 black-color mb-0 mr-2">Location :- </p> */}
                    {/* <h6 className="title black-color mb-0"><Link to={classData.location_url} target="_blank" title={classData.location_url}>{classData.location}</Link></h6>

                        </div>
                      </div>
                    </div> */}
                    <div className="row mt-3">
                      <div className="col-12 col-md-12">
                        <div className="text-left">
                          <p className="font-14 black-color mb-0"> For Online Classes
                            {/* Online Class */}
                          </p>
                          {/* <h5 className="title black-color mb-0">{classData.duration}</h5> */}

                          {classStatus == 3 ?
                            classData.zoom_meeting_url ?
                              <h5 className="title black-color mb-0"><Link to={classData.zoom_meeting_url} target="_blank" title={classData.zoom_meeting_url}>Click to Start Class</Link></h5>
                              :
                              <h5 className='title black-color mb-0' >No Link Available</h5>
                            :
                            <h5 className='title black-color mb-0' >Link provide once checked-in</h5>
                          }
                        </div>
                      </div>
                    </div>
                    {/* <!-- end of row --> */}
                    {/* <div className="row mt-2" >
                      <div className="col-12 col-md-12">
                        <div className="text-left">
                          <p className="font-14 black-color mb-0"> Web Link </p>
                          {classData.web_link &&
                            <h5 className="title black-color mb-0" > <Link to={classData.web_link} target="_blank"><i className="fa fa-link" aria-hidden="true" title={classData.web_link}></i>
                            </Link></h5>}
                          {!classData.web_link &&
                            <h5 className="title black-color mb-0" >N/A </h5>}
                        </div>
                      </div>
                    </div> */}
                    {/* <!-- end of row --> */}
                  </div>
                </div>
                {/* <!-- end of col-md-4 --> */}

                <div className="col-12 col-md-4 mb-4">
                  <p className="black-color font-w-100 text-uppercase font-20"> description </p>
                  <div className="white-bg box-shadow1 border-radius6 p-3 mt-3 text-left fixed-hight description-box">
                    <p className="font-14 black-color mb-0"> Description </p>
                    <p className="brand-color3 font-12 mb-2">{classData.description} </p>
                  </div>
                </div>
                {/* <!-- end of col-md-4 --> */}
              </div>
            </div>
          </div>

          <div className="mb-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="white-bg box-shadow1 border-radius6 p-4">
                    <div className="row">
                      <div className="col-6">
                        <div className="section-heading pt-2 pb-4 text-left">
                          <p className="black-color font-w-100 text-uppercase font-20"> staff / instructors </p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="section-heading pt-2 pb-3 text-right">
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {instrustorData?.length > 0 && instrustorData.map((itemss) => (
                        <div className="col-12 col-md-3 mb-4" >
                          <div className="activity-card box-shadow1 border-radius6 white-bg p-4">
                            <div className="col-12 mt-0 mt-md-4 mt-lg-0">
                              <div className="activity-card-details text-left">
                                <a href="Javascript:void(0);"
                                  onClick={() => redirectUrl(itemss.id, '/instructor-detail', '')}
                                >
                                  <div className="text-center">
                                    <div className="img-height box-shadow1 mx-auto">
                                      <img src={itemss.profile_img} className="img-fluid" />
                                    </div>
                                    <p className="black-color font-w-100 font-16 mt-3 mb-2">{itemss.name}  {itemss.lastname} </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {customerDetail?.length != 0 &&
                    <div  >
                      <div className="row py-4">
                        <div className="col-8">
                          <h5 className="title black-color font-w-500 text-uppercase font-16 mb-0"> User Details </h5>
                        </div>
                      </div>
                      <div className="row  mt-3">
                        {customerDetail.map((items) => (
                          <div className="col-md-4 mb-4">
                            <div className="class-detail-slot-box box-shadow1 border-radius6 p-3 bg-white">
                              <div className="row">
                                <div className="col-3">
                                  <div className="profile-img box-shadow1">
                                    <img src={items.profile_img} className="img-fluid" />
                                  </div>
                                </div>
                                <div className="col-9">
                                  <h5 className="brand-color3 heading-titlenew mb-1">{items.name}
                                    {items.lastname}</h5>
                                  <ul className="d-none">
                                    <li className="flex-fill">
                                      <p className="black-color paratitle font-w-400 font-14">{items.Age}, {items.gender}</p>
                                    </li>
                                  </ul>
                                  <div>
                                    <div className="d-flex align-items-center">
                                      <button className="btn btn-green-grad">{items.attendance_status}</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                  {customerDetail?.length == 0 &&
                    <div className="row" >
                      <div className="">
                        <p className="font-awesome black-color font-18 d-inline mx-2 "> No User Available</p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid pr-0">
            {passData?.length != 0 &&
              <div className="home-inner-available studio-padding-left">
                <div>
                  <p className="black-color font-w-100 text-uppercase font-20"> available passes </p>
                </div>
                {passData?.length <= 1 &&
                  <div className="row new-slider-logo" >
                    {passData.map((items) => (
                      <div className="col-6" >
                        <div className="mb-5" >
                          <div className="available-card" >
                            <a href="Javascript:void(0);" onClick={() => redirectUrl(items, '/pass-detail', currentUrl)}>
                              <div className="available-blue-card p-3 p-sm-4">
                                <div className="row mx-0 ">
                                  <div className="pass-logo">
                                    <img src={items.business_logo} className="img-fluid" />
                                  </div>
                                  <div className="flex-fill pr-4 pl-5 passTextwidth">
                                    <h5 className="white-color font-14 mb-2 mt-0">{items.pass_name}</h5>
                                    <div>
                                      {items.pass_type_name === 'Recurring Membership' ? (
                                        <h5 className="white-color font-14 mb-2 mt-0">
                                          {items.full_price} <span className="font-12"> Plus Tax </span>
                                        </h5>
                                      ) : (
                                        <h5 className="white-color font-14 mb-2 mt-0">
                                          {items.amount} <span className="font-12"> Plus Tax </span>
                                        </h5>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-5 align-items-end">
                                  <div className="col-8 text-left pr-0">
                                    <p className="mb-0 white-color paratitle"> Valid For {items.pass_validity}</p>
                                  </div>
                                </div>
                              </div>
                            </a>
                            <div className="studio-card-select">
                              {items.favourite == 0 &&
                                <i className="fas fa-heart brand-color3 font-22" onClick={() => addFav(items.pass_id, 1)}
                                ></i>}
                              {items.favourite == 1 &&
                                <i className="fas fa-heart brand-color2 font-22" onClick={() => addFav(items.pass_id, 0)}
                                ></i>}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                }
                {/*  Recurring passes*/}


                <div>
                  <p className="black-color font-w-100 text-uppercase font-14 ml-2">Recurring passes  </p>
                </div>

                {passData?.length > 0 && passData.filter((data) => (data.pass_type_name == "Recurring Membership")) ?
                  <>
                    <PassSlider_New pass={passData.filter((data) => (data.pass_type_name == "Recurring Membership"))} businessDl={businessDetail} addFav={addFav} redirectUrl={redirectUrl} currentUrl={currentUrl} />
                  </>
                  :
                  <>
                    <div className="row" >
                      <div className="col-4"></div>
                      <div className="col-4">
                        <div className="text-center">
                          <p className="font-awesome black-color font-18 d-inline mx-2 " > No Pass Available</p>
                        </div>
                      </div>
                      <div className="col-4"></div>
                    </div>
                  </>
                }
                {/* Time based */}
                <div>
                  <p className="black-color font-w-100 text-uppercase font-14 ml-2">Time based  </p>
                </div>
                {passData?.length > 0 && passData.filter((data) => (data.pass_type_name == "Time Frame")) ?
                  <>
                    <PassSlider_New pass={passData.filter((data) => (data.pass_type_name == "Time Frame"))} businessDl={businessDetail} addFav={addFav} redirectUrl={redirectUrl} currentUrl={currentUrl} />
                    {/* <PassSlider pass={passData.filter((data) => (data.pass_type_name == "Time Frame"))} businessDl={businessDetail} addFav={addFav} redirectUrl={redirectUrl} currentUrl={currentUrl} /> */}
                  </>
                  :
                  <>
                    <div className="row" >
                      <div className="col-4"></div>
                      <div className="col-4">
                        <div className="text-center">
                          <p className="font-awesome black-color font-18 d-inline mx-2 " > No Pass Available</p>
                        </div>
                      </div>
                      <div className="col-4"></div>
                    </div>
                  </>
                }


                {/* 
                 */}
                <div>
                  <p className="black-color font-w-100 text-uppercase font-14 ml-2">Punch Card</p>
                </div>
                {passData?.length > 0 && passData.filter((data) => (data?.pass_type_name == "Punch Card" && data?.is_recurring_billing != 'yes' )) ?
                  <PassSlider_New pass={passData.filter((data) => (data?.pass_type_name == "Punch Card" && data?.is_recurring_billing != 'yes'))} businessDl={businessDetail} addFav={addFav} redirectUrl={redirectUrl} currentUrl={currentUrl} />

                  :
                  <>
                    <div className="row" >
                      <div className="col-4"></div>
                      <div className="col-4">
                        <div className="text-center">
                          <p className="font-awesome black-color font-18 d-inline mx-2 " > No Pass Available</p>
                        </div>
                      </div>
                      <div className="col-4"></div>
                    </div>
                  </>
                }

                <div className="pb-1">
                  <p className="black-color font-w-100 text-uppercase font-14 ml-2">Recurring Punch Card</p>
                </div>
                {passData?.length > 0 && passData.filter((data) => (data?.pass_type_name == "Punch Card" && data?.is_recurring_billing == 'yes')) ?

                  <PassSlider_New pass={passData.filter((data) => (data?.pass_type_name == "Punch Card" && data?.is_recurring_billing == 'yes'))} businessDl={businessDetail} addFav={addFav} redirectUrl={redirectUrl} currentUrl={currentUrl} />

                  :
                  <>
                    <div className="row" >
                      <div className="col-4"></div>
                      <div className="col-4">
                        <div className="text-center">
                          <p className="font-awesome black-color font-18 d-inline mx-2 " > No Pass Available</p>
                        </div>
                      </div>
                      <div className="col-4"></div>
                    </div>
                  </>
                }
                {/* {passData.length == 0 &&
                  <div className="row" >
                    <div className="col-4"></div>
                    <div className="col-4">
                      <div className="text-center">
                        <p className="font-awesome black-color font-18 d-inline mx-2 " > No Pass Available</p>
                      </div>
                    </div>
                    <div className="col-4"></div>
                  </div>
                } */}
              </div>
            }
          </div>
        </div>
      </div>

      <Innerfooter />

      <Modal show={passSelectModal} className="modal fade modalwidth" id="PassSelectModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-body border-radius6 box-shadow1 p-5 white-bg">
          <div className="home-inner-available">
            <div>
              <p className="black-color font-w-100 text-uppercase font-20"> Purchased passes </p>
            </div>
            {purchasedPassData && purchasedPassData?.length != 0 ?
              <>
                {purchasedPassData.map((items) => (
                  <div className="available-card m-3">
                    <div className="available-blue-card p-3 p-sm-4">
                      <div className="row mx-0 ">
                        <div className="pass-logo">
                          <img src={items.business_logo} className="img-fluid" />
                        </div>
                        <div className="flex-fill pr-4 pl-5 passTextwidth">
                          <h5 className="white-color font-14 mb-2 mt-0">{items.pass_name}</h5>
                          <h5 className="white-color font-14 mb-2 mt-0">$ {items.full_price} <span className="font-12"> Plus Tax </span></h5>
                        </div>
                      </div>
                      <div className="row mt-5 align-items-end">
                        <div className="col-8 text-left pr-0">
                          {items.pass_type_category == 'PunchCard' &&
                            <p className="white-color paratitle" >Remaining Classes : {items.remaining_count} of {items.total_count}</p>
                          }
                          {items.pass_type_category == 'TimeFrame' || items.pass_type_category == 'Other' || items.pass_type_category == 'Recurring Membership' &&
                            <p className="white-color paratitle" >Remaining Days : {items.remaining_count} of {items.total_count}</p>
                          }
                          <p className="mb-0 white-color paratitle"> Start Date :{items.start_date_utc_new} </p>
                          <p className="white-color paratitle">Expiration Date : {items.end_date_utc_new}</p>
                        </div>
                      </div>
                    </div>
                    <div className="studio-card-select">
                      <input type="radio" checked={passID === items.pass_id}
                        id={items.pass_id} value={items.pass_id} name={items.pass_id}
                        onClick={(event) => getPassId(event)}
                      />
                    </div>
                  </div>
                ))}
              </>
              :
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                  <div className="text-center">
                    <p className="font-awesome black-color font-18 d-inline mx-2 " > No Pass Purchased</p>
                  </div>
                </div>
                <div className="col-4"></div>
              </div>

            }
            <div className="form-group px-3 pt-0 pt-sm-4 mb-0 mb-sm-2 d-flex buttonWidth justify-content-center">
              <button type="button" data-dismiss="modal" id="btn_close11" className="btn btn-green-grad text-uppercase mt-3 mr-3" data-backdrop="static" data-keyboard="false" onClick={() => passID ? changePassStatus1('singup', '1') : ''}
              > Submit </button>
              <button type="button" data-dismiss="modal" id="btn_close1"
                className="btn btn-blue-grad text-uppercase mt-3" data-backdrop="static" data-keyboard="false" onClick={() => setPassSelectModal(false)}
              > close </button>
            </div>
          </div>
        </div>
      </Modal>


      <div className="modal fade" id="covid19" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"  >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">

            <div className="modal-body white-bg box-shadow1 border-radius6 p-4">
              <h4 >  COVID 19 Pre-Screening Questionnaire

                Answer each of the following questions before entering the building:</h4>
              <form >

                <div className="form-group profile-change-password">
                  <p className="text-left font-14 "> Have you traveled outside of the Province/State in the last 14 days?  </p>
                  <div className="d-flex flex-wrap form group">
                    <div className="profile-gender-button covid-positive">
                      <input type='radio' id='yes' value="1" name='radio' />
                      <label for='yes'>Yes</label>
                    </div>
                    <div className="profile-gender-button">
                      <input type='radio' id='no' value="0" name='radio' />
                      <label for='no'>No</label>
                    </div>
                    <div className="invalid-feedback">
                      <div >Answer is Requeired</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <p className="text-left font-14 "> Have you been in contact with someone who has tested positive for COVID 19 in the past 14 days?  </p>
                  <div className="d-flex flex-wrap form group">
                    <div className="profile-gender-button covid-positive">
                      <input type='radio' id='yes1' value="1" name='radio1' />
                      <label for='yes1'>Yes</label>
                    </div>              <div className="profile-gender-button">

                      <input type='radio' id='no1' value="0" name='radio1' />
                      <label for='no1'>No</label>
                    </div></div>
                  <input id="" type="text" value="" className="form-control white" placeholder="DOB" />
                  <div className="invalid-feedback">
                    <div >Answer is Requeired</div>
                  </div>
                </div>

                <div className="form-group profile-change-password">
                  <p className="text-left font-14 "> Have you tested positive for COVID 19 in past 14 days? </p>
                  <div className="d-flex flex-wrap form group">
                    <div className="profile-gender-button covid-positive">
                      <input type='radio' id='yes2' value="1" name='radio2' />
                      <label for='yes2'>Yes</label>
                    </div>              <div className="profile-gender-button">

                      <input type='radio' id='no2' value="0" name='radio2' />
                      <label for='no2'>No</label>
                    </div></div>
                  <div className="invalid-feedback">
                    <div >Answer is Requeired</div>
                  </div>
                </div>

                <div className="form-group relative">
                  <p className="text-left font-14 ">Do you have any of the following: </p>

                  <p className="text-left font-14 "></p>
                  <div className="checkboxtypebtn" >
                    <input type="checkbox" className="form-control" name="checkbox" id="qqq.id" value="qqq.id"
                    />
                    <label for="qqq.id"><span>qqq.text</span></label>
                  </div>
                </div>

                <div className="form-group px-3 pt-0 pt-sm-4 mb-0 mb-sm-2">
                  <button type="button" className="btn btn-green-grad text-uppercase w-100 mt-3 " data-backdrop="static" data-keyboard="false" > <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Save </button>
                  <button type="button" data-dismiss="modal" id="btn_close21"
                    className="btn btn-blue-grad text-uppercase w-100 mt-3" data-backdrop="static" data-keyboard="false"
                  > close </button>
                </div>

              </form>
              <h5>
                Note:- If you are not feeling well, stay home and contact us over the phone or by email to cancel your visit.

              </h5>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>



      <div className="modal fade" id="Infocovid19" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"  >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">

            <div className="modal-body white-bg box-shadow1 border-radius6 p-4">
              <h4 >  COVID 19 Pre-Screening Questionnaire

                Answer each of the following questions before entering the building:</h4>
              <form >

                <div className="form-group profile-change-password" >
                  <p className="text-left font-14 "> qq.question_text </p>
                  <div className="d-flex flex-wrap form group">
                    <div className="profile-gender-button covid-positive">
                      <input type='radio' id="qq.id1" value="1" name='radioqq.id' />
                      <label for='qq.id1'>Yes</label>
                    </div>
                    <div className="profile-gender-button">
                      <input type='radio' id="qq.id2" value="0" name='radioqq.id' />
                      <label for='qq.id2'>No</label>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap form group symptoms" >

                    <div className="checkboxtypebtn" >
                      <input type="checkbox" className="form-control" name="checkbox" id="qqq.id" value="qqq.id"
                        checked="qqq.checked" readOnly disabled />
                      <label for="qqq.id"><span>qqq.text</span></label>
                    </div>
                  </div>
                </div>
                <div className="form-group px-3 pt-0 pt-sm-4 mb-0 mb-sm-2">
                  <button type="button" data-dismiss="modal" id="btn_close1"
                    className="btn btn-blue-grad text-uppercase w-100 mt-3" data-backdrop="static" data-keyboard="false"
                  > close </button>
                </div>

              </form>
              <h5>
                Note:- If you are not feeling well, stay home and contact us over the phone or by email to cancel your visit.

              </h5>
            </div>

          </div>
        </div>
      </div>





    </>
  )
}
