import axios from "axios";
// import moment from 'moment-timezone'; 
const moment = require('moment-timezone');
const currentTimeZone = moment.tz.guess();

// Create a new Axios instance
const https = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    // "Content-type": "application/json", // You can include this if needed for specific requests
    // 'Content-Type' : 'application/json',
    // 'deviceType':'Browser',
    //'deviceId':'',
    'language': 'en',
    'version': '1',
    'timeZone': currentTimeZone,
    'timeZoneOffset': '',
  }
});

// Declare these variables outside the if block
let lati = "";
let long = "";
let userID = "";
let parantIDS = "";
let new_parent_id = "";
let roll = "";

// Add request interceptor to update headers before sending a request
https.interceptors.request.use((config) => {

  if (localStorage.getItem('w_loginDetail')) {
    const userInfo = JSON.parse(localStorage.getItem('w_loginDetail'));
    lati = userInfo?.lat;
    long = userInfo?.lang;
    userID = userInfo?.id;
    parantIDS = userInfo?.id;
    roll = userInfo?.role_id;
  }
  if (localStorage.getItem('new_parent_id')) {
    new_parent_id = localStorage.getItem('new_parent_id');
  }
  // Get the dynamic values from localStorage and update the headers
  config.headers.lat = lati || "";
  config.headers.long = long || "";
  config.headers.userid = userID || "";
  config.headers.parentId = parantIDS || "";
  config.headers.role = roll || "";
  config.headers.newParentId = new_parent_id | '';

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default https;
