import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API_get_business_detail, Add_become_member_staff, Instructor_get_business_detail, favourite, mobile_free_passes_list_API, staff_register_studio } from '../../../Static_Services/Staff_Services';
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg';
import { get_business_detail } from '../../../Static_Services/StaticServices';
import { Modal } from 'react-bootstrap';

export const DashboardTopSection = ({ setLoder = "",getBusinessDetail }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [businessDetail, setBusinessDetail] = useState();
    const [userDetail, setUserDetail] = useState();
    const [currentUrl, setCurrentUrl] = useState();
    const [become_clientModal, setBecome_clientModal] = useState(false);

    const [freePass, setFreePass] = useState();


    const getFreePasses = async () => {
        const bzId = localStorage.getItem('bzid');
        const data = { "page_no": "1", "business_id": bzId }
        const resp = await mobile_free_passes_list_API(data);
        if (resp) {
            const data = resp?.data?.data[0];
            setFreePass(data);
        }
    }


    const [checks, setChecks] = useState(false);
    const getchekevent = (e) => {
        const cehcked = e.target.checked;
        setChecks(cehcked);
    }

    useEffect(() => {
        if (localStorage.getItem("userDetail")) {
            setUserDetail(JSON.parse(localStorage.getItem("userDetail")));
        }
        if (location.pathname) {
            setCurrentUrl(location.pathname);
        }
    }, [])

    const getDetail = async () => {
        const bzid = localStorage.getItem('bzid');
        let resp = '';
        if (bzid != null) {
            const fData = {
                "business_id": bzid
            }
            if (userDetail && userDetail.role_id == '3') {
                resp = await API_get_business_detail(fData);
            } else {
                resp = await Instructor_get_business_detail(fData)
            }
            if (resp) {
                localStorage.setItem('bzDetails', '');
                setBusinessDetail(resp.data.data);
                localStorage.setItem('bzDetails', JSON.stringify(resp.data.data));
            }
        }
    }
    useEffect(() => {
        if (userDetail) {
            getDetail();
            getFreePasses();
        }
    }, [userDetail])

    const addFav = async (id, status, type) => {
        const fData = {
            "service_type": type,
            "service_id": id,
            "status": status
        }
        const resp = await favourite(fData);
        if (resp) {
            successAlert(resp.data.message);
            getDetail();
        }
    }

    const RegisterStudio = async () => {

        const detail = { "business_id": businessDetail.business_id, "email": businessDetail.email };

        const resp = await staff_register_studio(detail);
        if (resp) {
            const responce = resp.data;
            successAlert(responce.message);
            getDetail();
            navigate('/my-home');
        }

    }

    const redirectdetail = () => {
        if (businessDetail.user_booking == 1) {
            navigate('/studio-detail', { state: { Id: businessDetail.business_id, backurl: currentUrl } });
        } else {
            errorAlert('Seems you have not purchased anything from this studio. Please purchase to continue.');
        }
    }


    const clientSubmit = async () => {
        let detail = '';
        if (checks == true) {
            if (setLoder) {
                setLoder(true);
            }       
            let fData = {
                "business_id": businessDetail.business_id,
                "waiver": checks,
                'service_type': freePass?.pass_type_id,
                'service_id': freePass?.pass_id,
                'amount': freePass?.amount,
                'quantity': 1,
                'user_email':userDetail.email,
            };
            const resp = await Add_become_member_staff(fData);
            if (resp) {
                let response = resp.data;
                let b_Detail = { ...businessDetail };
                if (businessDetail) {
                    detail = {
                        "business_id": businessDetail.business_id,
                        'business_name': businessDetail.business_name,
                        'address': businessDetail.address,
                        'logo': businessDetail.logo,
                        'business_img': businessDetail.business_img,
                        'email': businessDetail.email,
                        'latitude': businessDetail.latitude,
                        'longitude': businessDetail.longitude,
                        'business_phone': businessDetail.business_phone,
                        'favourite': businessDetail.favourite,
                        'isCloverPurchase': businessDetail.isCloverPurchase,
                        'is_already_client': response.data,
                        'studio_message': businessDetail.studio_message
                    };
                }
                if (setLoder) {
                    setLoder(false);
                }
                localStorage.setItem('w_BusinessDetail', JSON.stringify(detail));

                b_Detail.is_already_client = response.data;
                b_Detail.user_booking = 1;
                setBusinessDetail(b_Detail);
                getBusinessDetail();
                // businessDetail = JSON.parse(localStorage.getItem('w_BusinessDetail'));
                // businessDetail.user_booking = 1;
                successAlert(response.message);
                setBecome_clientModal(false);
            }
        } else {
            errorAlert('Please check term & condition');
        }
        setLoder(false);
    }

    return (
        <>
            {userDetail && businessDetail &&
                <>
                    <div className="studio-details-upper box-shadow1 white-bg mb-3">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="std-details-upper-left d-flex align-items-center justify-content-center" style={{ backgroundImage: `url(${businessDetail.business_img})`, backgroundSize: "contain", backgroundPosition: "center", minHeight: "406px", backgroundRepeat: 'no-repeat' }}>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="std-details-upper-right text-center p-3">
                                    <div className="">
                                        <img src={businessDetail.logo} className="mb-4 log-img" style={{ objectFit: "contain", }} alt='' />
                                        <h4 className="title dark-color mb-2"> {businessDetail.business_name} </h4>
                                        {businessDetail.address.length <= 40 &&
                                            <p className="brand-color3 font-12 mb-0"> {businessDetail.address} </p>
                                        }
                                        {businessDetail.address.length > 40 &&
                                            <p className="brand-color3 font-12 mb-0">{businessDetail.address.substring(0, 40) + '...'} </p>
                                        }
                                        <ul className="studio-contact-details my-2">
                                            <li className="ml-0">
                                                <Link to={`tel:${businessDetail.business_phone}`} target="blank">
                                                    <img src="clientassets/img/ic_call_web.png" alt='' />
                                                    <span> Call </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`http://maps.google.com/maps?q=${businessDetail.latitude},${businessDetail.longitude},14z?hl=en@${businessDetail.address}`} target="blank" >
                                                    <img src="clientassets/img/ic_direction_web.png" alt='' />
                                                    <span> Directions </span>
                                                </Link>
                                            </li>
                                            <li> <Link to={`https://mail.google.com/mail/u/0/?view=cm&to=${businessDetail.email}&:&bcc&cc&fs=1&tf=1`} target="blank"> <img src="clientassets/img/ic_email_web.png" alt='' />
                                                <span> Email </span>
                                            </Link>
                                            </li>
                                            <li className="mr-0">
                                                {businessDetail.favourite == 0 &&
                                                    <div style={{ cursor: 'pointer' }} onClick={() => addFav(businessDetail.business_id, 1, 1)}>
                                                        <img src="clientassets/img/ic_unfavourite_web.png" alt='' />
                                                        <span> Favourite </span>
                                                    </div>
                                                }
                                                {businessDetail.favourite == 1 &&
                                                    <div style={{ cursor: 'pointer' }} onClick={() => addFav(businessDetail.business_id, 0, 1)}>
                                                        <img src="clientassets/img/ic_favourite_web.png" alt='' />
                                                        <span> Favourite </span>
                                                    </div>
                                                }
                                            </li>
                                        </ul>
                                        {userDetail.role_id == 4 &&
                                            <ul className="studio-service-details">
                                                <div>
                                                    {businessDetail.is_register == 0 &&
                                                        <button type="button" className="btn btn-green-grad w-100 mb-2" onClick={() => RegisterStudio()}> Register With Studio </button>
                                                    }
                                                    {businessDetail.is_register == 2 &&
                                                        <button type="button" className="btn btn-danger w-100  mb-2" > Request Rejected </button>
                                                    }
                                                    {businessDetail.is_register == 1 &&
                                                        <button type="button" className="btn btn-green-grad w-100 mb-2" onClick={() => navigate('/studio-detail', { state: { id: businessDetail.business_id } })}> My Studio Details </button>
                                                    }
                                                    {businessDetail.is_register == 3 &&
                                                        <button type="button" className="btn btn-warning w-100  mb-2"> Request Pending </button>
                                                    }
                                                </div>
                                                {businessDetail.is_register == 1 &&

                                                    <li className="ml-md-0">
                                                        <Link to="/classess" state={{ id: businessDetail.business_id }}> <img src="clientassets/img/ic_class.png" alt='' /> <span> Classes </span> </Link>
                                                    </li>
                                                }
                                                {businessDetail.is_register == 1 &&
                                                    <li >
                                                        <Link to="/appointment" state={{ id: businessDetail.business_id }}> <img src="clientassets/img/ic_appointment.png" alt='' /> <span> Appointment </span> </Link>
                                                    </li >
                                                }
                                                {businessDetail.is_register == 1 &&
                                                    <li className="mr-md-0">
                                                        <Link to="/workshopss" state={{ id: businessDetail.business_id }}> <img src="clientassets/img/ic_workshop.png" alt='' /> <span> Workshops </span> </Link>
                                                    </li>
                                                }
                                            </ul>
                                        }
                                        {userDetail.role_id == 3 &&
                                            <ul className="studio-service-details">
                                                {businessDetail.is_already_client == 0 &&
                                                    <button type="button" className="btn btn-green-grad w-100 mb-2" onClick={() => setBecome_clientModal(true)}> Become a Client </button>

                                                    // <Link to="Javascript:void(0);" >
                                                    //     <button type="button" className="btn btn-green-grad w-100 mb-2" data-toggle="modal" data-target="#become_client" data-backdrop="static" data-keyboard="false"  > Become a Client </button>
                                                    // </Link>
                                                }
                                                {businessDetail.is_already_client == 2 &&
                                                    <button type="button" className="btn btn-green-grad w-100 mb-2" onClick={() => redirectdetail()}> My Studio Details </button>

                                                }
                                                <li className="ml-md-0">
                                                    <Link to='/my-classes' state={{ id: businessDetail.business_id }}> <img src="clientassets/img/ic_class.png" alt='' /> <span> Classes </span> </Link>
                                                </li>
                                                <li>
                                                    <Link to='/staff' state={{ id: businessDetail.business_id }} > <img src="clientassets/img/ic_instructor.png" alt='' /> <span> Instructors </span> </Link>
                                                </li>
                                                <li>
                                                    <Link to='/services' state={{ id: businessDetail.business_id }}> <img src="clientassets/img/ic_service.png" alt='' /> <span> Services </span> </Link>
                                                </li>
                                                <li >
                                                    <Link to='/allproduct' state={{ id: businessDetail.business_id }}> <img src="clientassets/img/ic_product_new.png" alt='' /> <span> Products </span> </Link>
                                                </li>
                                                <li className="mr-md-0">
                                                    <Link to='/workshops' state={{ id: businessDetail.business_id }}> <img src="clientassets/img/ic_workshop.png" alt='' /> <span> Workshops </span> </Link>
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-right mb-3">
                        <button type="button" className="btn btn-blue-grad px-2 text-uppercase" onClick={() => window.history.back()} >Back </button>
                    </div>
                </>
            }



            <Modal show={become_clientModal} className="modal fade addfilter-modal">
                {/* <div className="modal-dialog modal-dialog-centered" id="ownModalWidth" role="document"> */}
                <div className="modal-content">
                    <div className="modal-body light-bg box-shadow1 border-radius6 px-4 pb-4 px-md-5 pb-md-5" >
                        <button type="button" className="close modalclose" style={{ cursor: 'pointer' }} onClick={() => setBecome_clientModal(false)}>
                            <span aria-hidden="true"> <img src="clientassets/img/ic_close.png" className="img-fluid" alt='' /> </span>
                        </button>
                        {/* <div class="modal-body white-bg box-shadow1 border-radius6 p-4 p-md-5"> */}

                        <div class="modal-logo text-center pb-3 pb-sm-5">
                            <img src="clientassets/img/logo_green_small.png" alt='' />
                        </div>

                        <div class="modal-bottom-content border-radius6 ">
                            <p>
                                {businessDetail && businessDetail.become_client_privacy_data}
                            </p>
                            <input type="checkbox"
                                onChange={getchekevent}
                            /> By checking the box you agree to accept the Terms and Conditions above and share your personal information.
                            <div class="d-flex">
                                <button type="button" class="btn btn-green-grad text-uppercase w-50 mt-2"
                                    onClick={clientSubmit}
                                > Submit </button>
                                <button type="button" data-dismiss="modal" onClick={() => setBecome_clientModal(false)} class="btn btn-green-grad text-uppercase w-50 mt-2" data-backdrop="static" data-keyboard="false"> close </button>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </Modal >
        </>
    )
}
