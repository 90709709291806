import React, { useEffect, useRef, useState } from 'react'
import './Profile.css'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter1'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import { Modal, Spinner } from 'react-bootstrap'
import { API_get_switch_user_profile, staff_changepassword, staff_get_countries, staff_get_profile, staff_profile_update, toDate } from '../../../Static_Services/Staff_Services'
import PlaceAutoCompleted from '../../Components/CommanPages/PlaceAutoCompleted'
export const Profile = () => {

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentDate, setCurrentDate] = useState(new Date().getDate());
    const [maxDate, setmaxDate] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState();
    const [userinfo, setUserinfo] = useState([]);
    const [memberDetail, setMemberDetail] = useState([])
    const [parentDetail, setParentDetail] = useState([]);


    const [loder, setLoder] = useState(false);
    const [userSubject, setUserSubject] = useState();
    const [contentStatus, setContentStatus] = useState();
    const [imgURL, setImgURL] = useState();
    const [userProfile, setUserProfile] = useState();
    const [countryList, setCountryList] = useState([]);
    const [profileDetail, setProfileDetail] = useState();
    const [dob, setDob] = useState();
    const [gender, setGender] = useState();

    const fileInputRef = useRef(null);
    const [switchUserModal, setSwitchUserModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [passShow, setPassShow] = useState(false);

    const mxDate = new Date(currentYear - 18, currentMonth, currentDate);

    const [cordinates, setCordinates] = useState();

    const [mainFormData, setMainFormData] = useState({
        name: '', lastname: '', email: '', dob: '', country_code: '', mobile: '', address: '', street: '', country: '', state: '', city: '', emergency_contact_person: '', emergency_country_code: '', emergency_contact_no: '', onluCountryCode: '', emgOnlyCountryCode: '', zipcode: '', lang: '', lat: '',
    data:''});
    const [errorFormData, setErrorFormData] = useState({
        name: '', lastname: '', email: '', dob: '', country_code: '', mobile: '', address: '', street: '', country: '', state: '', city: '', emergency_contact_person: '', emergency_country_code: '', emergency_contact_no: ''
    });

    useEffect(() => {
        if (cordinates) {
            let adr = cordinates.getAddress;
            let str = cordinates.getLocation_name;
            let ctr = cordinates.getCountry;
            let cit = cordinates.getCity;
            let zip = cordinates.getZIP_Code;
            let sta = cordinates.getState;
            if (cordinates.getAddress) { setMainFormData((pre) => ({ ...pre, 'address': adr, 'street': str, 'country': ctr, 'city': cit, 'zipcode': zip, 'lang': cordinates.getLng, 'lat': cordinates.getLat, 'state': sta })); }
        }
    }, [cordinates])

    useEffect(() => {
        if (mxDate) {
            setmaxDate(mxDate);
        }
        if (location.pathname) {
            setCurrentUrl(location.pathname);
        }
        if (localStorage.getItem('w_loginDetail')) {
            setUserinfo(JSON.parse(localStorage.getItem('w_loginDetail')))
        }
        if (localStorage.getItem('w_MemberDetail')) {
            setMemberDetail(JSON.parse(localStorage.getItem('w_MemberDetail')));
        }
        if (localStorage.getItem('w_ParentDetail')) {
            setParentDetail(JSON.parse(localStorage.getItem('w_ParentDetail')))
        }
        getCountry();
        getProfile();
    }, [localStorage.getItem('w_loginDetail'),localStorage.getItem('w_MemberDetail'),localStorage.getItem('w_ParentDetail')]);


    const [imagePath, setImagePath] = useState(null);
    const [selectedFile, setSelectedFile] = useState();
    const getUploadFileDetail = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            var mimeType = event.target.files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                errorAlert("Only images are supported.");
                return;
            }
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = (e) => {
                    const result = e.target.result;
                    setImgURL(result);
                    setImagePath(file);
                };
            }
        }
    }



    useEffect(() => {
        if (profileDetail) {
            setMainFormData((pre) => ({
                ...pre,
                name: profileDetail.name,
                lastname: profileDetail.lastname,
                email: profileDetail.email,
                dob: profileDetail.date_of_birth,
                country_code: profileDetail.country_code,
                mobile: profileDetail.mobile,
                address: profileDetail.address,
                onluCountryCode: profileDetail.onlyCountryCode,
                emgOnlyCountryCode: profileDetail.emgOnlyCountryCode,
                street: profileDetail.street,
                country: profileDetail.country,
                state: profileDetail.state,
                city: profileDetail.city,
                emergency_contact_person: profileDetail.emergency_contact_person,
                emergency_country_code: profileDetail.emergency_country_code,
                emergency_contact_no: profileDetail.emergency_contact_no,
                data:profileDetail
            }));
        }

    }, [profileDetail])


    const formHandleChange = (e) => {

        // console.log('====>',countryList,'data--->',(e.target.value));
        let { name, value } = e.target;
        if (name == 'country_code') {
            if (countryList && countryList.length > 0) {
                const data = countryList.filter(e => e.name === value);
                let code = data[0].code;
                let name = data[0].name;
                setMainFormData((pre) => ({ ...pre, 'country_code': code, 'onluCountryCode': code, 'country': name }));
                setErrorFormData((pre) => ({ ...pre, [name]: '' }))
            }
        } else {
            setMainFormData((pre) => ({ ...pre, [name]: value }));
            setErrorFormData((pre) => ({ ...pre, [name]: '' }))
        }
    }


    const [forgotError, setforgotError] = useState({
        old_password: "",
        new_password: "",
        password: ""
    })

    const forgotErrorHandler = (e) => {
        let { name, value } = e.target;
        setforgotError((pre) => ({ ...pre, [name]: '' }));
    }

    const changePassword = async (e) => {
        e.preventDefault();
        let isValid = 1;
        const formData = new FormData(e.target);
        const formValue = Object.fromEntries(formData.entries());

        const old_password = formValue.old_password;
        const new_password = formValue.new_password;
        const password = formValue.password;
        const PassPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.])[A-Za-z\d$@$!%*?&#.]{8,30}$/;
        if (!old_password) {
            setforgotError((pre) => ({ ...pre, old_password: 'Old Password is required' }));
            isValid = 2;
        }
        if (!new_password) {
            setforgotError((pre) => ({ ...pre, new_password: 'New Password is required' }));
            isValid = 3;
        }

        if (new_password !== password) {
            errorAlert('Password not match');
            isValid = 4;
        }
        if (!password) {
            setforgotError((pre) => ({ ...pre, password: 'Confirm Password is required' }));
            isValid = 5;
        }
        if (old_password && (!PassPattern.test(old_password) || old_password.length >= 30 || old_password.length <= 8)) {
            setforgotError((pre) => ({ ...pre, old_password: 'Old Password length must be 8 with at least one special character, one capital letter, one small letter and one number.' }));
            isValid = 6;
        }
        if (new_password && (!PassPattern.test(new_password) || new_password.length >= 30 || new_password.length <= 8)) {
            setforgotError((pre) => ({ ...pre, new_password: 'New Password length must be 8 with at least one special character, one capital letter, one small letter and one number.' }));
            isValid = 7;
        }
        if (password && (!PassPattern.test(password) || password.length >= 30 || password.length <= 8)) {
            setforgotError((pre) => ({ ...pre, password: 'New Password length must be 8 with at least one special character, one capital letter, one small letter and one number.' }));
            isValid = 8;
        }
        if (isValid === 1) {
            setLoder(true);
            var detail = { "old_password": old_password, "new_password": new_password }
            const resp = await staff_changepassword(detail);
            if (resp) {
                const response = resp.data;
                setLoder(false);
                setUserSubject(response.data);
                successAlert(response.message);
                e.target.reset();
                setPasswordModal(false);

            } else {
                setLoder(false);
            }

        } else {
            setLoder(false);
            return false;
        }
    }



    const updateProfileHandler = async (maindata) => {
        let data = maindata.data;
        if (data) {
            setLoder(true);
            let formData = new FormData();
            formData.append('password', data.password);
            formData.append('name', mainFormData.name);
            formData.append('lastname', mainFormData.lastname);
            formData.append('gender', gender || data.gender);
            formData.append('country_code', mainFormData.country_code);
            formData.append('mobile', mainFormData.mobile);
            formData.append('date_of_birth', mainFormData.dob);
            formData.append('address', mainFormData.address);
            formData.append('city', mainFormData.city);
            formData.append('state', mainFormData.state);
            formData.append('country', mainFormData.country);
            formData.append('about', data.about);
            formData.append('zipcode', mainFormData.zipcode || data.zipcode);
            formData.append('lat', mainFormData.lat || data.lat);
            formData.append('lang', mainFormData.lang || data.lang);
            formData.append('street', mainFormData.street || data.street);

            // formData.append('image',imgURL);
            formData.append('role_id', data.role_id);

            formData.append('device_type', '');
            formData.append('device_token', '');
            formData.append('emergency_contact_person', mainFormData.emergency_contact_person || data.emergency_contact_person);
            formData.append('emergency_country_code', mainFormData.emergency_country_code || data.emergency_country_code);
            formData.append('emergency_contact_no', mainFormData.emergency_contact_no || data.emergency_contact_no);
            // formData.append('skills', data.skill);
            // formData.append('experience', data.experience);

            if (selectedFile) {
                formData.append('image', selectedFile);
            }
            const resp = await staff_profile_update(formData)

            if (resp) {
                const response = resp.data;
                setLoder(false);
                setImgURL('');
                if (response.data.id == parentDetail.id) {
                    localStorage.setItem('w_ParentDetail', JSON.stringify(response.data));
                }
                localStorage.setItem('w_loginDetail', JSON.stringify(response.data));
                localStorage.setItem("userDetail", JSON.stringify(response.data));

                setUserSubject(response.data);

                if (currentUrl == '/profile') {
                    navigate('/studio');
                }
                else {
                    navigate('/home');
                }
                successAlert(response.message);
            }
            else {
                setLoder(false);
            }
        }
       
    }


    const closeModal = () => {
        // this.submitted = false;
        // this.userpassword.reset();
    }

    const getGender = (event) => {
        setGender(event.target.value);
    }


    const getProfile1 = async () => {
        setLoder(true);
        let cd = '';
        const resp = await API_get_switch_user_profile();
        if (resp) {
            const response = resp.data;
            countryList.map((el) => {
                if (el.code == response.data.emergency_country_code) {
                    cd = el.name + ' (' + el.code + ')';
                }
            })
            if (response.data.date_of_birth) {
                setDob(response.data.date_of_birth);
            }
            let ProDetail = response.data;
            ProDetail.onlyCountryCode = response.data.country_code;
            ProDetail.emgOnlyCountryCode = response.data.emergency_country_code;
            // ProDetail.emergency_country_code = cd;


            if (ProDetail.gender) {
                setGender(ProDetail.gender);
            }
            ProDetail.mobile1 = ProDetail.country_code + '  ' + ProDetail.mobile;
            localStorage.setItem('w_loginDetail', JSON.stringify(response.data));
            localStorage.setItem("userDetail", JSON.stringify(response.data));
            setParentDetail(ProDetail);
            getProfile();
            setLoder(false);

            if (currentUrl == '/profile') {
                navigate('/profiles');
            } else if (currentUrl == '/profiles') {
                navigate('/profile');
            }

            setLoder(false);

        } else {
            setLoder(false);

        }

    }

    const getCountry = async () => {
        let cd = '';
        const resp = await staff_get_countries();
        if (resp) {
            const response = resp.data;
            response.data.map((el) => {
                if (el.name == 'United States') {
                    cd = el.name + ' (' + el.code + ')';
                }
            })
            setCountryList(response.data);
            let prDetail = profileDetail;
            if (prDetail) {
                prDetail.emergency_country_code = cd;
            }
            setProfileDetail(prDetail);
            // 
        }

    }

    const handleAddressChange = async (address = '') => {

        // this.profileDetail.lat = address.geometry.location.lat();
        // this.profileDetail.lang = address.geometry.location.lng();
        // this.userProfile.value.address = this.profileDetail.address = address.formatted_address;
        // // this.userProfile.value.location_name = address.name;
        // this.userProfile.value.location_name = this.profileDetail.street = address.name;
        // for (var i = 0; i < address.address_components.length; i++) {

        //   var state = address.address_components[i].types[0];
        //   if (state == "administrative_area_level_1") {
        //     this.userProfile.value.state = this.profileDetail.state = address.address_components[i].long_name;
        //     // this.userProfile.value.state = address.address_components[i].long_name;
        //   }
        //   var city = address.address_components[i].types[0];
        //   if (city == "administrative_area_level_2") {
        //     // this.userProfile.value.city = address.address_components[i].long_name;
        //     this.userProfile.value.city = this.profileDetail.city = address.address_components[i].long_name;
        //   }
        //   var country = address.address_components[i].types[0];
        //   if (country == "country") {
        //     // this.userProfile.value.country = address.address_components[i].long_name;
        //     this.userProfile.value.country = this.profileDetail.country = address.address_components[i].long_name;
        //   }
        //   var postal_code = address.address_components[i].types[0];
        //   if (postal_code == "postal_code") {
        //     this.profileDetail.zipcode = address.address_components[i].long_name;
        //   }
        // }
    }

    const getProfile = async () => {
        setLoder(true);
        let cd;
        let cn;
        let today = new Date();
        let currYear = today.getFullYear();

        const resp = await staff_get_profile()
        if (resp) {
            let response = resp.data;

            countryList.map((el) => {
                if (el.code == response.data.emergency_country_code) {
                    cd = el.name + '(' + el.code + ')';
                }
            })

            countryList.map((el) => {
                if (el.code == response.data.country_code) {
                    cn = el.name + ' (' + el.code + ')';

                }
            })
            if (response.data.date_of_birth) {
                setDob(response.data.date_of_birth);
            }
            let proDetail = response.data;
            proDetail.onlyCountryCode = response.data.country_code;
            proDetail.emgOnlyCountryCode = response.data.emergency_country_code;
            proDetail.emergency_country_code = cd;
            proDetail.country_code = cn;
            if (proDetail.gender) {
                setGender(proDetail.gender);
            }
            setProfileDetail(proDetail);
            setLoder(false);
        } else {
            setLoder(false)
        }
    }

    const changeUserProfile = (id) => {
        let inf = userinfo
        inf.id = id;
        setSwitchUserModal(false);
        localStorage.setItem('w_loginDetail', JSON.stringify(inf));
        localStorage.setItem("userDetail", JSON.stringify(inf))
        getProfile1();
    }

    const changeImage = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        getCountry();
        getProfile();
    }, [])



    return (
        <>

            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />
            <div className="body-grey">
                {/* <!-- Profile Section --> */}

                <div className="profile-section py-5">
                    <div className="container">
                        <div className="white-bg p-4 p-md-5 border-radius6 box-shadow1">
                            {/* <form */}
                            {/* // [formGroup]="userProfile" */}
                            {/* > */}
                                <div className="row">
                                    <div className="col-12 pb-4">
                                        <div className="profile-details">
                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-3">
                                                    <div className="profile-detail-image ">
                                                        <input
                                                            type="file"
                                                            ref={fileInputRef}
                                                            id="file1"
                                                            name="image"
                                                            accept="image/*"
                                                            onChange={(e) => getUploadFileDetail(e)}
                                                            style={{ display: "none", }}
                                                        />

                                                        <div className="userprofile_img box-shadow1">
                                                            {!imgURL &&
                                                                <img src={mainFormData && mainFormData.data && mainFormData.data.profile_img ?mainFormData.data.profile_img  : ''} className="img-fluid border-radius100 w-100" style={{ height: "200px" }} />}
                                                            {imgURL &&
                                                                <img src={imgURL} className="img-fluid border-radius100  w-100" style={{ height: "200px" }} />}
                                                            <a href="Javascript:void(0);" className="brand-color1 font-14 edit-profileimg box-shadow1"
                                                                onClick={() => changeImage()}
                                                                id="upfile1" style={{ cursor: "pointer" }}> <i className="fas fa-camera"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <div className="profile-detail-text">
                                                        <h2 className="title dark-color font-24 mb-2 text-capitalize">{mainFormData && mainFormData.data && mainFormData.data.name + ' '}
                                                            {mainFormData && mainFormData.data && mainFormData.data.lastname}</h2>
                                                        <p className="brand-color3"> {mainFormData && mainFormData.data && mainFormData.data.email}</p>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-md-3">
                                                    <button type='button' className="btn btn-blue-grad text-uppercase w-100" data-toggle="modal"
                                                        data-target="#profileChange" onClick={() => setSwitchUserModal(true)} data-backdrop="static" data-keyboard="false">Switch User</button>

                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* **** First Name **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">First Name </p>
                                            <input type="text" className="form-control text-capitalize" value={mainFormData && mainFormData.name} placeholder="Enter your name" name='name' onChange={formHandleChange} />
                                        </div>
                                    </div>
                                    {/* **** Last Name **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1">Last Name </p>
                                            <input id="" type="text" name="lastname" className="form-control text-capitalize"
                                                value={mainFormData && mainFormData.lastname} onChange={formHandleChange}
                                                placeholder="Enter your name" />
                                        </div>
                                    </div>

                                    {/* **** Email Name **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Email </p>
                                            <input id="" type="email" name="email" className="form-control"
                                                value={mainFormData && mainFormData.email}
                                                style={{ backgroundColor: "white" }} placeholder="Enter your e-mail" readOnly />
                                        </div>
                                    </div>
                                    {/* **** DOB Name **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> DOB </p>
                                            <input type="date" name="dob" className="form-control"
                                                onChange={formHandleChange}
                                                value={mainFormData && mainFormData.dob}
                                                style={{ backgroundColor: "white" }} placeholder="Enter your Date Of Birth" />
                                        </div>
                                    </div>
                                    {/* **** Country Code Name **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Country Code</p>
                                            <select className="form-control select-class" name="country_code" value={mainFormData.country}
                                                onChange={formHandleChange} required>
                                                {countryList.length > 0 && countryList.map((code) => (
                                                    <>
                                                        <option

                                                            //  selected={mainFormData && mainFormData.country_code == code.code} 
                                                            value={code.name}>{code.name} ({code.code}) </option>
                                                    </>
                                                ))
                                                }

                                            </select>
                                        </div>
                                    </div>
                                    {/* **** Contact Number **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Contact Number </p>
                                            <input id="" type="number" name="mobile" className="form-control"
                                                maxLength={12} onChange={formHandleChange}
                                                value={mainFormData && mainFormData.mobile}
                                                placeholder="Enter number of your emergency contact person"
                                            />
                                        </div>
                                    </div>

                                    {/* **** Address **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            {/* <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Address </p> */}
                                            <PlaceAutoCompleted
                                                label={'Address'}
                                                value={mainFormData && mainFormData.address ? mainFormData.address : ''}
                                                setCordinates={setCordinates}
                                            />
                                            {/* <input id="" name="address" className="form-control"
                                                value={mainFormData && mainFormData.address}
                                                onChange={formHandleChange}
                                                autoComplete="off"
                                                placeholder="Enter your address" */}
                                            {/* // ngx-google-places-autocomplete */}

                                            {/* // /> */}
                                        </div>
                                    </div>
                                    {/* **** Street **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Street </p>
                                            <input className="form-control" placeholder="Street Address" name='street'
                                                value={mainFormData && mainFormData.street}
                                                onChange={formHandleChange}
                                            />
                                        </div>
                                    </div>
                                    {/* **** Country **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Country </p>
                                            <input id="" name="country" className="form-control" value={mainFormData && mainFormData.country} placeholder="Enter your country"
                                                onChange={formHandleChange}
                                            />
                                        </div>
                                    </div>
                                    {/* **** State**** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> State/Province </p>
                                            <input id="" name="state" className="form-control" value={mainFormData && mainFormData.state}
                                                placeholder="Enter your state/province"
                                                onChange={formHandleChange}
                                            />
                                        </div>
                                    </div>
                                    {/* **** City **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> City </p>
                                            <input id="" name="city" className="form-control" value={mainFormData && mainFormData.city}
                                                placeholder="Enter your city"
                                                onChange={formHandleChange}
                                            />
                                        </div>
                                    </div>
                                    {/* **** Emergency Contact Person **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Emergency Contact Person </p>
                                            <input id="" name="emergency_contact_person" className="form-control"
                                                value={mainFormData && mainFormData.emergency_contact_person}
                                                placeholder="Enter name of your emergency contact person"
                                                onChange={formHandleChange}
                                            />
                                        </div>
                                    </div>
                                    {/* **** Emergency Country Code **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Emergency Country Code </p>

                                            <select
                                                className="form-control select-class"
                                                name="emergency_country_code"
                                                value={mainFormData.emgOnlyCountryCode}
                                                onChange={formHandleChange}
                                            >
                                                {countryList.length > 0 &&
                                                    countryList.map((code, i) => (
                                                        <option key={i} value={code.code}>
                                                            {code.name + ' (' + code.code + ')'}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    {/* **** Emergency Contact Number **** */}
                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                        <div className="form-group">
                                            <p className="black-color font-w-900 text-left font-18 mb-0 ml-2 pl-1"> Emergency Contact Number </p>
                                            <input id="" type="number" name="emergency_contact_no" className="form-control"
                                                onChange={formHandleChange}
                                                value={mainFormData && mainFormData.emergency_contact_no}
                                                placeholder="Enter number of your emergency contact person"

                                            />
                                        </div>
                                    </div>
                                    {/* <!-- Gendar --> */}
                                    <div className="col-12 col-sm-8 mt-2 mt-md-3">
                                        <div className="form-group profile-gender mb-0">
                                            <p className="brand-color3 text-left font-14 mb-3"> Gender </p>
                                            <div className="profile-gender-button">
                                                <input type='radio' id='male' value="male" name='radio' checked={gender == 'male'}
                                                    onChange={(e) => getGender(e)}
                                                />
                                                <label htmlFor='male'>Male</label>

                                                <input type='radio' id='female' value="female" name='radio'
                                                    checked={gender == 'female'}
                                                    onChange={(e) => getGender(e)}
                                                />
                                                <label htmlFor='female'>Female</label>

                                                <input type='radio' id='other' value="other" name='radio'
                                                    checked={gender == 'other'}
                                                    onChange={(e) => getGender(e)}
                                                />
                                                <label htmlFor='other'>Other</label>
                                            </div>
                                            {/* <!-- end of profile-gender-button --> */}
                                        </div>
                                    </div>
                                    {/* <!-- Gender --> */}

                                    <div className="col-12 col-sm-4 mt-2 mt-sm-4 mt-md-4 mt-lg-5 pt-0 pt-sm-4  password-align">
                                        <a href="Javascript:void(0);" id="changepass" data-toggle="modal" data-target="#profileChangepass"
                                            data-backdrop="static" data-keyboard="false" onClick={() => setPasswordModal(true)} className="brand-color1 font-14"> Change Password? </a>
                                    </div>
                                    {/* <!-- Change Password --> */}
                                    <div className="col-12 col-sm-4 mt-2 mt-md-3"></div>
                                    <div className="col-12 col-sm-4 mt-2 mt-md-3">
                                        <button type="button" className="btn btn-blue-grad text-uppercase w-100"
                                            onClick={() => updateProfileHandler(mainFormData)}>  Update </button>
                                    </div>
                                    <div className="col-12 col-sm-4 mt-2 mt-md-3"></div>
                                </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>

            </div>
            <Innerfooter />
            {/* <!-- Modal Popup (Change - Password ) --> */}
            <Modal show={passwordModal} className="modal fade">
                <div className="modal-body white-bg box-shadow1 border-radius6 p-4 p-md-5">
                    <div className="modal-logo text-center pb-3 pb-sm-5">
                        <img src="clientassets/img/logo_green_small.png" alt='' />
                    </div>

                    <div className="modal-bottom-content blue-gradient border-radius6 py-3 py-sm-5 px-2">
                        <form onSubmit={changePassword} className='ChangePassword'>
                            <div className="form-group profile-change-password">
                                <p className="text-white text-left font-14 mb-0 ml-3"> Old Password </p>
                                <input id="password-field" type={passShow ? "text" : "password"} className="form-control"
                                    name='old_password' onChange={forgotErrorHandler} />
                                <span toggle="#password-field" onClick={() => setPassShow(!passShow)}
                                    className="fa fa-fw fa-eye field-icon toggle-password brand-color3"></span>
                                <small className='text-danger'>{forgotError.old_password}</small>

                            </div>
                            {/* <!-- Old Password --> */}

                            <div className="form-group">
                                <input id="" type="text" className="form-control white" placeholder="New Password"
                                    name='new_password' onChange={forgotErrorHandler} />
                                <small className='text-danger'>{forgotError.new_password}</small>
                            </div>
                            {/* <!-- New Password --> */}

                            <div className="form-group">
                                <input id="" type="text" className="form-control white" placeholder="Confirm Password"
                                    name='password' onChange={forgotErrorHandler} />
                                <small className='text-danger'>{forgotError.password}</small>
                            </div>
                            {/* <!-- Confirm Password --> */}

                            <div className="form-group px-3 pt-0 pt-sm-4 mb-0 mb-sm-2">
                                <button type="submit" className="btn text-uppercase w-100 mt-3" > update </button>
                                <button type="button" data-dismiss="modal" id="btn_close" className="btn text-uppercase w-100 mt-3"
                                    data-backdrop="static" data-keyboard="false" onClick={() => setPasswordModal(false)}>
                                    close </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <Modal show={switchUserModal} className="modal fade" >

                <div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setSwitchUserModal(false)}>
                        <span aria-hidden="true"> <img src="clientassets/img/ic_close.png" className="img-fluid" alt='' /> </span>
                    </button>
                </div>
                <div className="modal-body white-bg box-shadow1 border-radius6 p-2 p-md-5">

                    <div className="modal-logo text-center pb-sm-2">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="className-detail-slot-box box-shadow1 border-radius6 p-3 bg-white">
                                            <div className="row" data-dismiss="modal" style={{ cursor: 'pointer' }} onClick={() => changeUserProfile(parentDetail.id)}>
                                                <div className="col-3">
                                                    <div className="profile-img box-shadow1">
                                                        <img src={parentDetail && parentDetail.profile_img} className="img-fluid" alt='' />
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <h5 className="brand-color3 heading-titlenew mb-1">{parentDetail && parentDetail.name}
                                                        {parentDetail && parentDetail.lastname}</h5>
                                                    <ul className="d-flex">
                                                        <li className="flex-fill">
                                                            <p className="black-color paratitle font-w-400 font-14">{parentDetail && parentDetail.email}
                                                            </p>
                                                        </li>
                                                    </ul>
                                                    <div>
                                                        <div className="d-flex align-items-center">
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-bottom-content blue-gradient border-radius6 py-3 py-sm-2 px-2">
                        <div className="row">
                            <div className="col-12">
                                {memberDetail.length != 0 &&
                                    <div>
                                        <div className="row ">
                                            {memberDetail.map((items) => (
                                                <div className="col-md-12 mb-2">
                                                    <div className="class-detail-slot-box box-shadow1 border-radius6 p-3 bg-white" style={{ cursor: 'pointer' }} data-dismiss="modal"
                                                        onClick={() => changeUserProfile(items.id)}
                                                    >
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className="profile-img box-shadow1">
                                                                    <img src={items.image} className="img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div className="col-9">
                                                                <h5 className="brand-color3 heading-titlenew mb-1">{items.member_name}
                                                                </h5>

                                                                <div>
                                                                    <div className="d-flex align-items-center">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                }
                                {memberDetail.length == 0 &&
                                    <div className="row" >
                                        <div className="text-center">
                                            <p className="font-awesome black-color font-18 d-inline mx-2 "> No Member Available</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* <!-- End of Modal Popup (Change - userprofile ) --> */}

        </>
    )
}
