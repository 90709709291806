import React from 'react'
import { InnerHeader } from '../../Components/inner-header/InnerHeader1'
import { Innerfooter } from '../../Components/inner-footer/Innerfooter'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { DashboardToSectionDetails } from '../../Components/CommanPages/DashboardToSectionDetails'
import { Modal, Spinner } from 'react-bootstrap'
import { errorAlert, successAlert } from '../../../Static_Services/Alertmsg'
import { API_add_cart, API_cardGet, API_cart_list, API_clover_buy_now_single, API_pay_at_desk, API_remove_cart, API_term_and_condition_recurring_pass, App_add_free_pass_API, ExpiryCheck, favourite, staff_passes_details } from '../../../Static_Services/Staff_Services'
import moment from 'moment'
import Swal from 'sweetalert2'
import logo from '../../../Images/logo_white_web.png';

export const Pasesdetails = () => {
    const [userInfo, setUserInfo] = useState([]);
    const [businessDetail, setBusinessDetail] = useState([])
    const [loder, setLoder] = useState(false);
    const location = useLocation();
    const [passId, setPassId] = useState();
    const [booking_id, setBooking_id] = useState();
    const [passDetail, setPassDetail] = useState();
    const [customerData, setCustomerData] = useState([]);
    const [savecard, setSavecard] = useState(0);
    const [cardType, setCardType] = useState();
    const navigate = useNavigate();
    const [cartData, setCartData] = useState([]);
    const [termsData, setTermsData] = useState()
    const [policy_detail, setPolicy_detail] = useState();
    const [paymentval, setPaymentval] = useState();
    const [passCount, setPassCount] = useState(1);
    const [passAmount, setPassAmount] = useState();
    const [passTax, setPassTax] = useState();
    const [passTotalAmount, setPassTotalAmount] = useState();
    const [paynow, setPaynow] = useState();
    const [policyModal, setPolicyModal] = useState(false);



    useEffect(() => {
        if (localStorage.getItem('w_loginDetail')) {
            setUserInfo(JSON.parse(localStorage.getItem('w_loginDetail')));
        }
        if (localStorage.getItem('w_BusinessDetail')) {
            setBusinessDetail(JSON.parse(localStorage.getItem('w_BusinessDetail')));
        }
        const navData = location.state;
        if (navData && navData.Id) {
            localStorage.setItem('w_PassId', JSON.stringify(navData.Id));
            setPassId(navData.Id.id);
            setBooking_id(navData.Id.user_booking_id);
        }
        if (localStorage.getItem('w_PassId')) {
            const passdetail = JSON.parse(localStorage.getItem('w_PassId'));
            setPassId(passdetail.id);
            setBooking_id(passdetail.user_booking_id);
        }
    }, [])



    const redirectUrl = (url, Id) => {
        navigate(url, { state: { Id: Id } });
    }

    const updateCount = (value) => {
        if (value == '+') {
            if (passCount < passDetail.product_quantity) {
                passCount = passCount + 1;
            } else {
                errorAlert('Pass not Available');
                return false;
            }
        } else {
            if (passCount > 1) {
                passCount = passCount - 1;
            } else {
                errorAlert('Minimun Quantity is 1')
                return false;
            }
        }
    }
    const getAmountTax = () => {
        const PsAmount = passDetail.amount;
        setPassAmount(PsAmount)
        const PsTax = passDetail.tax;
        setPassTax(PsTax);
        const PsTotalAmount = PsAmount + PsTax;
        setPassTotalAmount(PsTotalAmount);
    }


    const [expiryDate, setExpiryDate] = useState();
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (e.nativeEvent.inputType === "deleteContentBackward") {
            if (inputValue.endsWith('/')) {
                setExpiryDate(inputValue.slice(0, -1));
            } else { setExpiryDate(inputValue); }
        } else {
            if (inputValue.length === 2 && inputValue.charAt(2) !== '/') {
                setExpiryDate(inputValue + '/');
            } else if (inputValue.length === 8) { return; } else { setExpiryDate(inputValue); }
        }
    };

    const [carderror, setCarderror] = useState({ name: "", crnumber: "", dates: "", cvv: "" });
    const [cardData, setCardData] = useState({ name: "", crnumber: "", dates: "", cvv: "" });
    const CardInputChange = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/^\s+/g, '');
        setCardData((pre) => ({ ...pre, [name]: cleanedValue }));
        setCarderror((pre) => ({ ...pre, [name]: "" }));
    }


    const payment = async () => {

        let isValid = 1;
        let expiry_month = '';
        let expiry_year = '';
        let expired = '';

        if (expiryDate) {
            expired = ExpiryCheck(expiryDate);
        }
        if (expired) {
            expiry_month = expired[0];
            expiry_year = expired[1];
        } else {
            setCarderror((pre) => ({ ...pre, dates: "* Valid Till is required" }));
            isValid = 2;
        }

        if (!cardData.name) { setCarderror((pre) => ({ ...pre, name: "* Card Holder Name is required" })); isValid = 3; };
        if (!cardData.crnumber) {
            setCarderror((pre) => ({ ...pre, crnumber: "* Card Number is required" }))
            isValid = 4;
        };
        if (!cardData.cvv) {
            setCarderror((pre) => ({ ...pre, cvv: "* CVV is required" }));
            isValid = 5;
        };
        if (isValid === 1) {
            setLoder(true);
            const detail = {
                "service_type": '1',
                "service_id": passDetail.pass_id,
                "quantity": 1,
                "grand_total": passDetail.pass_total_price,
                "slot_date": "",
                "slot_time_id": "",
                "token": '',
                "savecard": savecard,
                "card_id": '',
                "business_id": businessDetail.business_id,
                "number": cardData.crnumber,
                "expiry_month": expiry_month,
                "expiry_year": expiry_year,
                "cvd": cardData.cvv,
                "country_code": "",
                "customer_name": cardData.name,
            };
            const resp = await API_clover_buy_now_single(detail);
            if (resp) {
                const response = resp.data;
                setCardData({ name: "", crnumber: "", dates: "", cvv: "" });
                successAlert(response.message);
                setLoder(false);
                window.history.back();
            } else {
                setCardData({ name: "", crnumber: "", dates: "", cvv: "" });
                setLoder(false);
            }
        } else {
            setLoder(false);
            return false;
        }
    }

    const savePayment = async (card) => {
        setLoder(true);
        const detail = {
            "service_type": '1',
            "service_id": passDetail.pass_id,
            "quantity": 1,
            "grand_total": passDetail.pass_total_price,
            "slot_date": "",
            "slot_time_id": "",
            "token": card.card_token,
            "savecard": 0,
            "card_id": card.card_id,
            "business_id": businessDetail.business_id,
            "country_code": "",
        };


        const resp = await API_clover_buy_now_single(detail);
        if (resp) {
            const response = resp.data;
            successAlert(response.message);
            setLoder(false);
            window.history.back();
        } else {
            setLoder(false);
        }
    }

    const getPassInfo = async () => {
        setLoder(true);
        const detail = { "pass_id": passId, 'user_booking_id': booking_id };
        const resp = await staff_passes_details(detail);
        if (resp) {
            let response = resp.data;
            response.data.start_date_utc_new = moment.unix(response.data.start_date_utc).format('MMM DD YYYY');
            setPassDetail(response.data);
            setLoder(false)
        } else {
            setLoder(false)
        }
    }
    const addFav = async (id, status) => {
        setLoder(true);
        const detail = { "service_type": "2", "service_id": id, "status": status };
        const resp = await favourite(detail);
        if (resp) {
            const response = resp.data;
            successAlert(response.message);
            getPassInfo();
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    const getSaveCardDetail = async () => {
        setLoder(true);
        var detail = { "userid": userInfo.id, 'business_id': businessDetail.business_id };

        const resp = await API_cardGet(detail);
        if (resp) {
            const response = resp.data;
            response.data.map((el) => {
                el.checked = true;
            })
            setCustomerData(response.data);
            setLoder(false);
        } else {
            setLoder(false);
        }
    }
    const SavecardHandeler = (event) => {
        if (event.target.checked == true) {
            setSavecard(1);
        }
        else { setSavecard(0) }
    }

    const getCardType = (event) => {
        setCardType(event.target.id);
        customerData.map((el) => {
            if (el.card_id == cardType) {
                el.checked = event.target.checked;
            }
        })
    }

    const PayAtStudio = async () => {
        setLoder(true);
        const detail = {
            "service_type": '1',
            "service_id": passDetail.pass_id,
            "quantity": 1,
            "slot_date": "",
            "slot_time_id": "",
            "business_id": businessDetail.business_id,
            "amount": passAmount,
            "tax": passTax,
            "instructor_id": ""
        };
        const resp = await API_pay_at_desk(detail);
        if (resp) {
            successAlert(resp.data.message);
            setLoder(false)
            window.history.back();
        } else {
            setLoder(false)
        }
    }

    const AddCart = (Detail) => {

        if (cartData.length != 0) {
            if (businessDetail.business_id == cartData.business_id) {
                AddCartData(Detail);
            } else {
                Swal.fire({
                    title: 'Replace cart item?',
                    text: 'Your cart contains items from another studio. Do you want to discard the selection and add pass from new studio?.',
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                }).then((result) => {
                    if (result.value) {
                        RemoveCartDetail(Detail);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                    }
                })
            }
        } else {
            AddCartData(Detail);
        }
    }

    const AddCartData = async (Detail) => {
        setLoder(true);
        const detail = { "service_type": "1", "service_id": Detail.pass_id, "amount": Detail.amount, "quantity": "1" };
        const resp = await API_add_cart(detail);
        if (resp) {
            successAlert(resp.data.message);
            setLoder(false);
            getCartDetail();
            getPassInfo();
        } else {
            setLoder(false);
        }
    }


    const getCartDetail = async () => {
        const detail = { "pageid": "1" };

        const resp = await API_cart_list(detail);
        if (resp) {
            const response = resp.data;
            setCartData(response.data.business_details[0]);
        } else {
        }
    }

    const RemoveCartDetail = async (data) => {
        setLoder(true);
        const detail = { "remove_cart_type": "1" };

        const resp = await API_remove_cart(detail);
        if (resp) {
            AddCartData(data);
            setLoder(false);
        } else {
            setLoder(false);
        }
    }

    const Terms = (event) => {
        if (event.target.checked == true) {
            setTermsData(1);
        }
        else { setTermsData(0) }
    }
    const getContent = async () => {
        const resp = await API_term_and_condition_recurring_pass();
        if (resp && resp.data) {
            setPolicy_detail(resp.data.content);
        }
    }

    const passCheck = () => {
        if (passDetail.is_recring == 1) {
            if (termsData == 1) {
                setPaymentval(true)
            } else {
                return false;
            }
        } else {
            setPaymentval(true)
        }
    }

    useEffect(() => {
        if (passId) {
            getCartDetail();
            getContent();
            getPassInfo();
            getSaveCardDetail();
        }
    }, [booking_id, passId, businessDetail])


    const AddCartFreePass = async (data) => {
        const { pass_id, amount } = data;
        const { business_id } = businessDetail;

        if (!business_id) { errorAlert("Something went wrong .") };
        if (!pass_id) { errorAlert("Something went wrong .") };

        setLoder(true);
        try {
            const payload = {
                'service_type': 1,
                'service_id': pass_id,
                'amount': amount,
                'quantity': 1,
                'business_id': business_id,
            }
            const resp = await App_add_free_pass_API(payload);
            if (resp) {
                Swal.fire({
                    html: `
                <img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                <h4 class="title text-uppercase text-center mt-4 textWhite">
                  SUCCESS
                </h4>
                <p class="text-center my-4 textcolor">
                  <small class="textWhite">
                    ${resp.data.message}
                  </small>
                </p>
                `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                })
            }

        } catch (error) {
            console.error('@error---', error);

        } finally {
            setLoder(false);
        }



    }


    return (
        <>
            {loder ? <div className='MainLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div> : ""}
            <InnerHeader />
            <div className="body-grey">
                {/* <!-- My Studio Card Detials-Service Section --> */}
                <div class="studio-details">
                    <div class="container">
                        <DashboardToSectionDetails />
                        <div class="pb-5">
                            <div class="row">
                                <div class="col-6 text-left">
                                    <h4 class="title black-color text-uppercase"> Pass details </h4>
                                </div>
                                <div class="col-6 text-right mb-3">
                                    <button type="button" class="btn btn-blue-grad px-2 text-uppercase"
                                        onClick={() => window.history.back()}
                                    >Back </button>
                                </div>
                            </div>
                            {/* <!-- End of H\eading --> */}
                            {passDetail &&
                                <div class="s-d-appoint-block">
                                    <div class="row">
                                        <div class="col-12 col-lg-7 mt-3 mt-lg-0">
                                            <div class="home-inner-available border-radius6 box-shadow1 white-bg p-4">
                                                <div class="" >
                                                    <div class="available-card">
                                                        <div class="available-blue-card p-3 p-sm-4">
                                                            <div class="row mx-0 ">
                                                                <div class="col-4 pass-logo">
                                                                    <img src={passDetail && passDetail.business_logo} class="img-fluid" />
                                                                </div>
                                                                <div class="col-8 flex-fill pr-4 pl-5 passTextwidth">
                                                                    <h5 class="white-color font-14 mb-2 mt-0">{passDetail.pass_name}</h5>
                                                                    <div>
                                                                        {passDetail.pass_type_name === 'Recurring Membership' ? (
                                                                            <h5 className="white-color font-14 mb-2 mt-0">
                                                                                ${passDetail.full_price} <span className="font-12"> Plus Tax </span>
                                                                            </h5>
                                                                        ) : (
                                                                            <h5 className="white-color font-14 mb-2 mt-0">
                                                                                ${passDetail.amount} <span className="font-12"> Plus Tax </span>
                                                                            </h5>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="row mt-2 align-items-end">
                                                                {(passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 1) || (passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 0) &&
                                                                    <div class="col-12 text-left pr-0">
                                                                        {passDetail.pass_type_category == 'PunchCard' &&
                                                                            <p class="mb-0 white-color paratitle" > Remaining Classes : {passDetail.remaining_count} of {passDetail.total_count} </p>}
                                                                        {(passDetail.pass_type_category == 'TimeFrame' || passDetail.pass_type_category == 'Other' || passDetail.pass_type_category == 'Recurring Membership') &&
                                                                            <p class="mb-0 white-color paratitle"> Remaining Days : {passDetail.remaining_count} of {passDetail.total_count}</p>}
                                                                    </div>
                                                                }
                                                                <div class="col-8 text-left pr-0">
                                                                    <p class="mb-0 white-color paratitle"> Valid For {passDetail.pass_validity}</p>
                                                                    {(passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 1) || (passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 0) &&
                                                                        <p class="mb-0 white-color paratitle"> Start Date : {passDetail.start_date_utc_new} </p>}
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="studio-card-select">
                                                            {passDetail.favourite == 0 &&
                                                                <i class="fas fa-heart brand-color3 font-22"
                                                                    onClick={() => addFav(passDetail.pass_id, 1)}
                                                                ></i>}
                                                            {passDetail.favourite == 1 &&
                                                                <i class="fas fa-heart brand-color2 font-22"
                                                                    onClick={() => addFav(passDetail.pass_id, 0)}
                                                                ></i>}

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="row ml-2 mr-2 mt-2 d-block">
                                                    <h5 class="title black-color text-uppercase">Description </h5>
                                                    <p> {passDetail.description}</p>
                                                </div>
                                                {(!paynow && passDetail.pass_current_status == 1 && passDetail.is_one_time_purchase == 0) &&
                                                    <div class="row product-detail-section-btn px-2 pt-3 pb-1">
                                                        {userInfo.role_id == 3 &&
                                                            <>
                                                                {passDetail?.isAutoAdd == 'Yes' ?
                                                                    <div class="col-12">
                                                                        <button onClick={() => AddCartFreePass(passDetail)}
                                                                            class="btn new-blue btn-blue-grad font-w-600 w-100 text-uppercase py-3" > buy now </button>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div class="col-6">
                                                                            {
                                                                                passDetail.is_cart == 0 &&
                                                                                <button onClick={() => AddCart(passDetail)}
                                                                                    class="btn new-blue btn-blue-grad font-w-600 text-uppercase py-3" > Add to cart </button>
                                                                            }
                                                                            {passDetail.is_cart == 1 &&
                                                                                <Link to="/my-cart" class="btn new-blue btn-blue-grad font-w-600 text-uppercase mx-0 py-3"> Go to cart </Link>}
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                        {/* {passDetail?.isAutoAdd == 'Yes' ? */}
                                                        {userInfo.role_id == 3 && passDetail?.isAutoAdd == 'No' &&
                                                            <div class="col-6">
                                                                <button type="button" onClick={() => setPaynow(true)}
                                                                    class="btn new-green btn-green-grad font-w-600 text-uppercase mx-0 py-3"> buy now </button>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {(!paynow && passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 1) || (!paynow && passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 0) &&
                                                    <div class="row product-detail-section-btn px-2 pt-3 pb-1" >
                                                        <div class="col-12">
                                                            <h5 class="title green-color text-center">Pass Already Purchased </h5>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div class="col-12 col-lg-5 mt-5 mt-lg-0" >
                                            <div class="s-d-appoint-block-right border-radius6 box-shadow1 white-bg" >
                                                <div class="px-3 py-3 text-left light-bg border-t-lr-6">
                                                    <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> payment details </h5>
                                                </div>
                                                {/* <!-- top heading --> */}

                                                <div class="px-3 pt-4 pb-2 border-bottom">
                                                    <div class="row mb-2">
                                                        <div>
                                                            {passDetail.pass_type_name === 'Recurring Membership' ? (
                                                                <div className='row'>
                                                                    <div className="col-6 text-left">
                                                                        <p className="black-color font-w-100 font-18">Full Price</p>
                                                                    </div>
                                                                    <div className="col-6 text-right">
                                                                        <p className="black-color font-w-100 font-18">
                                                                            {businessDetail.currency}
                                                                            {passDetail.full_price}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-6 text-left">
                                                                        <p className="black-color font-w-100 font-18">Pro-Rated Amount</p>
                                                                    </div>
                                                                    <div className="col-6 text-right">
                                                                        <p className="black-color font-w-100 font-18">
                                                                            {businessDetail.currency}
                                                                            {parseFloat(passDetail.amount).toFixed(2)}
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            ) : (
                                                                <div className='row'>
                                                                    <div className="col-6 text-left">
                                                                        <p className="black-color font-w-100 font-18">
                                                                            Price({passCount} Item)
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-6 text-right">
                                                                        <p className="black-color font-w-100 font-18">
                                                                            {businessDetail.currency}
                                                                            {passDetail.amount}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-6 text-left">
                                                            <p class="black-color font-w-100 font-18"> Tax 1 </p>
                                                        </div>
                                                        <div class="col-6 text-right">
                                                            <p class="black-color font-w-100 font-18"> {businessDetail.currency}  {passDetail.tax1_rate}   </p>
                                                        </div>
                                                        <div class="col-6 text-left">
                                                            <p class="black-color font-w-100 font-18"> Tax 2 </p>
                                                        </div>
                                                        <div class="col-6 text-right">
                                                            <p class="black-color font-w-100 font-18"> {businessDetail.currency}  {passDetail.tax2_rate}  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- end of Appointment Fees: --> */}

                                                <div class="px-3 pt-4 pb-3 border-bottom">
                                                    <div class="row mb-2">
                                                        <div class="col-6 text-left">
                                                            {passDetail.pass_current_status == 1 && passDetail.is_one_time_purchase == 0 &&
                                                                <p class="title black-color font-w-100 font-18 mb-0"> Amount Payable </p>}
                                                            {(passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 1) || (passDetail.pass_current_status == 0 && passDetail.is_one_time_purchase == 0) &&
                                                                <p class="title black-color font-w-100 font-18 mb-0"> Total Paid </p>}
                                                        </div>
                                                        <div class="col-6 text-right">
                                                            <p class="title black-color font-w-100 font-18 mb-0">
                                                                {businessDetail.currency}  {passDetail.pass_total_price}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {!paymentval && paynow &&
                                                    <div class="px-3 py-3 text-center">
                                                        {passDetail.is_recring == 1 &&
                                                            <div >
                                                                <input type="checkbox" class="mt-2 mb-2"
                                                                    onChange={(e) => Terms(e)}
                                                                />  {policy_detail}
                                                            </div>
                                                        }
                                                        {businessDetail.isCloverPurchase &&
                                                            <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5 mt-2" onClick={() => passCheck()}
                                                            > checkout </button>}
                                                        {!businessDetail.isCloverPurchase &&
                                                            <p class="black-color font-w-100 font-18 mb-0 mb-3" >{businessDetail.studio_message} </p>}
                                                    </div>
                                                }
                                            </div>
                                            {paymentval &&
                                                <div class="px-3 py-3 text-center" >
                                                    <div class="client-buy-process border-radius6 box-shadow1 white-bg mt-2" >
                                                        <div class="p-3 text-left light-bg border-t-lr-6">
                                                            <h5 class="title black-color font-w-100 text-uppercase font-16 mb-0"> credit card / debit card </h5>
                                                        </div>
                                                        {/* <!-- top heading --> */}

                                                        <div class="px-3 pt-4 pb-3 border-bottom">
                                                            <div class="row mb-2">
                                                                <div class="col-6 text-left">
                                                                    <p class="title black-color font-w-100 font-18 mb-0"> Amount Payable </p>
                                                                </div>
                                                                <div class="col-6 text-right">
                                                                    <p class="title black-color font-w-100 font-18 mb-0">  {businessDetail.currency} {passDetail.pass_total_price} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!-- end of Amount Payable --> */}


                                                        <div class="client-payment-input border-radius6 box-shadow1 white-bg p-4">

                                                            <div class="row">
                                                                {customerData && customerData.map((cardsave) => (
                                                                    <div class="col-12 mb-3"  >
                                                                        {cardsave.length != 0 &&
                                                                            <div class="client-card-img white-bg border-radius6 box-shadow1">
                                                                                <label class="select-card select-card-img select-card-cvv mb-2">
                                                                                    {(cardsave.card_type == 'VISA' || cardsave.card_type == 'visa') &&
                                                                                        <img src="clientassets/img/visa_card.png" class="img-fluid payment_img" />}
                                                                                    {cardsave.card_type == 'MC' &&
                                                                                        <img src="clientassets/img/master_card.png" class="img-fluid payment_img" />}
                                                                                    {cardsave.card_type == 'AM' &&
                                                                                        <img src="clientassets/img/american_express.png" class="img-fluid payment_img" />}
                                                                                    <p class="mb-0 font-12 dark-color mt-2">{cardsave.number}  </p>
                                                                                    <h5 class="mb-0 font-12 dark-color mt-2">Valid Till(MM/YYYY) </h5>
                                                                                    <p class="mb-0 font-12 dark-color mt-2">{cardsave.expiry_month}  / {cardsave.expiry_year} </p>
                                                                                    <input type="radio" checked={cardType == cardsave.card_id} name="radio" id={cardsave.card_id} value={cardsave.card_id} onChange={(e) => getCardType(e)}
                                                                                    />
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                                {cardType == cardsave.card_id &&
                                                                                    <form >
                                                                                        <div class="text-center d-flex">
                                                                                            <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-3 ml-5"
                                                                                                onClick={() => savePayment(cardsave)}
                                                                                            > Pay </button>
                                                                                        </div>
                                                                                    </form>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                ))}

                                                                <div class="col-12 mb-3">
                                                                    <div class="white-bg border-radius6 box-shadow1">
                                                                        <label class="select-card"> New Card
                                                                            <input type="radio" checked={cardType == 'new'} name="radio" id="new" value="new"
                                                                                onChange={(e) => getCardType(e)}
                                                                            />
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- end of New Card --> */}

                                                            </div>
                                                            {/* <!-- end of row --> */}
                                                        </div>
                                                        {cardType == 'new' &&
                                                            <form>
                                                                <div class="row py-4 px-3">
                                                                    {/* Card Name */}
                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="form-group">
                                                                            <p className="brand-color3 text-left font-14 mb-2"> Name On Card </p>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                autoComplete="off"
                                                                                name='name'
                                                                                placeholder="Sophie Joe"
                                                                                value={cardData.name}
                                                                                onChange={CardInputChange}
                                                                            />
                                                                            <small className="text-danger">{carderror.name}</small>
                                                                        </div>
                                                                    </div>
                                                                    {/* <!-- Card Number --> */}
                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="form-group client-buy-input-cardno">
                                                                            <p className="brand-color3 text-left font-14 mb-2"> Card Number </p>
                                                                            <input
                                                                                type="text"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                placeholder="4121 4411 3131 8554"
                                                                                name='crnumber'
                                                                                value={cardData.crnumber}
                                                                                onChange={CardInputChange}
                                                                                maxLength={19}
                                                                            />
                                                                            <small className="text-danger">{carderror.crnumber}</small>
                                                                        </div>
                                                                    </div>

                                                                    {/* <!-- Exp... Dates --> */}
                                                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                                                        <div className="form-group">
                                                                            <p className="brand-color3 text-left font-14 mb-2"> Valid Till </p>
                                                                            <input
                                                                                type="text"
                                                                                name={'dates'}
                                                                                className="form-control"
                                                                                placeholder="MM/YYYY"
                                                                                value={expiryDate}
                                                                                onChange={handleInputChange}
                                                                                pattern="^[0-9]{2}/[0-9]{4}"
                                                                                autoComplete="new-password"
                                                                                lable={"Valid Through"}
                                                                            />
                                                                            <small className="text-danger">{carderror.dates}</small>
                                                                        </div>
                                                                    </div>
                                                                    {/* <!-- CVV  --> */}
                                                                    <div className="col-12 col-sm-6 mt-2 mt-md-3">
                                                                        <div className="form-group">
                                                                            <p className="brand-color3 text-left font-14 mb-2"> CVV </p>
                                                                            <input
                                                                                type="password"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                placeholder="eg. 201"
                                                                                name='cvv'
                                                                                value={cardData.cvv}
                                                                                onChange={CardInputChange}
                                                                                maxLength={3}
                                                                            />
                                                                            <small className="text-danger">{carderror.cvv}</small>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <input type="checkbox" checked={savecard === 1} onChange={(e) => SavecardHandeler(e)} /> Save Card
                                                                    </div>
                                                                    <div class="w-100 py-5 text-center">
                                                                        <button type="button" class="btn btn-blue-grad px-4 w-50 text-uppercase mb-0 mb-md-5"
                                                                            onClick={() => payment()}
                                                                        > Proceed to pay </button>
                                                                    </div>
                                                                    {/* <!-- end of button and view policy --> */}

                                                                </div>
                                                            </form>
                                                        }
                                                    </div>
                                                </div >
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                        </div >
                        {/* <!-- end of s-d-appoint --> */}


                    </div >
                    {/* <!-- end of container --> */}
                </div >
            </div >

            {/* <!-- CHECKOUT MODAL POPUP --> */}
            <Modal class="modal fade">
                <div class="modal-body border-radius6 box-shadow1 p-5 white-bg">
                    <div class="border-radius6 box-shadow-blue blue-gradient py-5 px-4 text-center">
                        <img src="clientassets/img/logo_white.png" class="img-fluid" />
                        <h4 class="title white-color text-uppercase my-4 font-w-600"> select checkout <br /> option </h4>
                        <button data-dismiss="modal" type="button" class="btn btn-white2 text-uppercase w-100 mb-4"
                            onClick={() => setPaymentval(true)} > pay now </button>
                        <button data-dismiss="modal" type="button" class="btn btn-white2 text-uppercase w-100"
                            onClick={() => PayAtStudio()}
                        > pay on pick up </button>
                    </div>
                </div>
            </Modal>
            {/* <!-- END OF CHECKOUT MODAL POPUP --> */}

            {/* <!-- CHECKOUT MODAL POPUP --> */}
            <Modal show={policyModal} class="modal fade" id="busLocation" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

                <button type="button" class="close text-right mr-2" data-dismiss="modal" aria-label="Close" onClick={() => setPolicyModal(false)} >
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body border-radius6 box-shadow1 p-5 light-bg">
                    <div class="text-center">
                        <h4 class="title black-color text-uppercase mt-4"> Terms & Conditions </h4>
                    </div>
                    <div className="border-radius6 box-shadow-blue blue-gradient p-4 text-center">
                        <div dangerouslySetInnerHTML={{ __html: policy_detail }}></div>
                    </div>

                </div>

            </Modal>
            <Innerfooter />
        </>
    )
}
